import classNames from "classnames";
import React from "react";
import { PortraitWithTooltip } from "../PortraitWithTooltip";
import { useChooseNameSelector } from "../store/selectorHooks";
import { useAppDispatch } from "../store/Store";
import { joinGame } from "../store/specialActions";
import "./ChoosePlayerName.css";

const firstParts = ["Noob", "Ego", "Troop", "Army"];
const secondParts = ["Slayer", "Killer", "Stomper", "Crusher"];
const suffixes = ["420", "69", "3000", "360"];
const frames: [string, string][] = [
    ["xXx_", "_xXx"],
    ["-->", "<--"],
    ["[RM] ", ""],
    ["[Faze] ", ""]
]

function pickRandomly<T>(array: T[]) {
    return array[Math.floor(Math.random() * array.length)];
}

function getRandomPlayerName() {
    const first = pickRandomly(firstParts);
    const second = pickRandomly(secondParts);
    const suffix = pickRandomly(suffixes);
    const [left, right] = pickRandomly(frames);
    return `${left}${first}${second}${suffix}${right}`
}

export function ChoosePlayerName() {
    const [name, setName] = React.useState("");
    const dispatch = useAppDispatch();

    return <div className="choose-player-name">
        <h1>Join Game</h1>
        Choose player name:&nbsp;
        <form onSubmit={() => dispatch(joinGame({ name }))}>
            <input type="text"
                placeholder={getRandomPlayerName()}
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <br />
            <button className="fancy-button" type="submit">Join</button>
        </form>

        <JoinedPlayersList />
    </div>;
}

function JoinedPlayersList() {
    const existingPlayers = useChooseNameSelector(state => state.gameInfo.players);
    const dispatch = useAppDispatch();

    return <div className="joined-players-list">
        {existingPlayers.map(player => <div className={classNames("joined-player", { "joined-players-connected": player.isConnected })} key={player.tag}>
            <div className="lobby-player-name"
                style={{ color: player.textColor, background: player.color }}
            >
                {player.name}
            </div>
            <PortraitWithTooltip className="lobby-player-portrait" src={player.portrait} />
            {!player.isConnected ? <button onClick={() => dispatch(joinGame({ name: player.tag }))}>Join as this player</button> : <div>(connected)</div>}
        </div>)
        }
    </div >
}
