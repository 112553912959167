import { AnyAction } from '@reduxjs/toolkit';
import { store } from '../store/Store';
import { serverInit, receiveState } from "../store/specialActions";
import AsyncWebSocket from './AsyncWebSocket';
import { ServerMessage, ClientMessage } from '../net/Messages';
import WebSocketCloseCode, { describeCloseCode } from "../net/WebSocketCloseCode";
import withTimeout from './withTimeout';
import { setDisconnected, receivePing } from './connectionSlice';
import ApplicationCloseCodes from './ApplicationCloseCodes';
import AutoResetEvent from './AutoResetEvent';
import * as immer from 'immer';
import jsonPatchesToImmerPatches from './jsonPatchesToImmerPatches';
import { decompress } from './CompressedHistory';
import { PlayerTag } from '../logic/GameState';

type ClientSocket = AsyncWebSocket<ServerMessage, ClientMessage>;
type ClosedState = { code: number, reason: string };
export default class GameClient {
    #socket: ClientSocket;
    #closedEvent = new AutoResetEvent(undefined);
    closed: ClosedState | undefined;
    private constructor(socket: ClientSocket) {
        this.#socket = socket;
    }
    static async connect(
        url: string,
        gameId: string,
        playerTag?: PlayerTag
    ): Promise<GameClient | null> {
        const connection = await AsyncWebSocket.createClientSocket<ServerMessage, ClientMessage>(url);
        if (connection === "NoConnection") {
            store.dispatch(setDisconnected());
            return null;
        }
        const socket = connection;
        const client = new GameClient(socket);
        let lastPingSent = performance.now();
        socket.send({
            type: "clientId",
            gameId,
            playerTag
        });
        const serverInitMessage = await socket.popNextMessage();
        if (serverInitMessage.type !== "serverInit") {
            throw new Error("Expected to receive the full game state");
        }

        const initTime = performance.now() - lastPingSent;
        lastPingSent = performance.now();

        store.dispatch(serverInit({
            initialState: serverInitMessage.initialState,
            initTime,
            history: decompress(serverInitMessage.history),
        }));

        store.dispatch(receivePing(performance.now() - lastPingSent));

        async function run() {
            while (true) {
                const next = await withTimeout(socket.waitForMessage(), 500);
                if (next === "timeout") {
                    const timeSinceAnswer = performance.now() - socket.lastMessageTime;
                    if (timeSinceAnswer > 5000) {
                        socket.close(ApplicationCloseCodes.TIMEOUT, "Application Server Timeout")
                    }
                    socket.send({ type: "ping" });
                    lastPingSent = performance.now();
                    continue;
                }
                const message = socket.popMessage();

                switch (message.type) {
                    case "error":
                        console.error("Server Error", message.message);
                        break;
                    case "patch":
                        const storeState = store.getState();
                        if (storeState.mode !== "multiplayer"
                            && storeState.mode !== "lobby")
                            throw new Error("Not in multiplayer or lobby mode!");
                        // We use immer's applyPatches function instead of
                        // fast-json-patch, since the latter makes a deep copy
                        // of the entire state tree instead of utilizing
                        // structural sharing.
                        const immerPatches = jsonPatchesToImmerPatches(message.patches);
                        const newState = immer.applyPatches(storeState, immerPatches);
                        store.dispatch(receiveState(newState));
                        break;
                    case "pong":
                        const pingTime = performance.now() - lastPingSent;
                        store.dispatch(receivePing(pingTime));
                        break;
                    case "socketClosed":
                        store.dispatch(setDisconnected());
                        console.log("Disconnected: ", message.code, message.reason || describeCloseCode(message.code));
                        client.closed = { code: message.code, reason: message.reason };
                        client.#closedEvent.fire();
                        return;
                    case "serverInit":
                        socket.close(ApplicationCloseCodes.UNEXPECTED_MESSAGE_TYPE, "Received server init message twice!");
                        break;
                    default:
                        // make sure that this is not reachable at compile time
                        const assertNever: never = message;
                        // if it's getting reached at runtime anyway, react to it
                        const messageType = (assertNever as any).type as string;
                        console.error("Unknown message type:", messageType);
                        socket.close(ApplicationCloseCodes.UNEXPECTED_MESSAGE_TYPE, `Unknown message type: ${messageType}`);
                        break;
                }
            }
        }
        run();
        return client;
    }

    sendAction(action: AnyAction) {
        this.#socket.send({
            type: "action",
            action: action
        })
    }

    close(message: string) {
        this.#socket.close(WebSocketCloseCode.NORMAL_CLOSURE, message);
    }

    async waitForClose(): Promise<ClosedState> {
        await this.#closedEvent.wait();
        return this.closed!;
    }
}
