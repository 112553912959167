export default class AutoResetEvent<T = void> {
    #currentPromise: Promise<T>;
    #resolveCurrentPromise: () => void = undefined!;
    #payload: T;
    public constructor(payload: T) {
        this.#payload = payload;
        this.#currentPromise = this.#reset();
    }

    public fire() {
        this.#resolveCurrentPromise();
        this.#currentPromise = this.#reset();
    }
    #reset(): Promise<T> {
        return new Promise(resolve => this.#resolveCurrentPromise = () => resolve(this.#payload));
    }
    public wait(): Promise<T> {
        return this.#currentPromise;
    }
}
