
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initializeExampleGame from './exampleGame';
import { applySingleAttack } from './applySingleAttack';
import { goToNextRound } from './goToNextRound';
import { getRemainingTroops } from './getRemainingTroops';
import { getPlaceableTroops } from './getPlaceableTroops';
import { NodeTag } from './GameState';
import { tradeCardsForTroops } from './tradeCardsForTroops';

interface MoveTroops {
    from: NodeTag,
    to: NodeTag,
    count: number,
}

interface PlacedTroops {
    target: NodeTag,
    count: number,
}

export const gameSlice = createSlice({
    name: "game",
    initialState: initializeExampleGame(),
    reducers: {
        tradeCards: (state) => {
            const phase = state.currentPhase;
            if (phase.type !== "place") {
                console.error("Invalid command: Not in placement phase");
                return;
            }
            const trade = tradeCardsForTroops(state.players[state.currentPlayer].cards);
            if (trade === null) {
                console.error("Invalid command: No viable cards to trade in");
                return;
            }
            const { newCards, gainedTroops } = trade;
            state.players[state.currentPlayer].cards = newCards;
            phase.availableTroops += gainedTroops;

            state.turnLog.push({
                type: "tradeCards",
                bonusTroops: gainedTroops,
            });
        },
        setPlacedTroops: (state, command: PayloadAction<PlacedTroops>) => {
            const phase = state.currentPhase;
            if (phase.type !== "place") {
                console.error("Invalid command: Not in placement phase");
                return;
            }
            const { target, count } = command.payload;
            if (state.nodes[target].owner !== state.currentPlayer) {
                console.error("Invalid command: Nodes do not belong to player");
                return;
            }
            const oldTroops = phase.troopDistribution[target] ?? 0;
            if (count - oldTroops > getRemainingTroops(phase)) {
                console.error("Invalid command: Not enough troops");
                return;
            }
            if (count < 0) {
                console.error("Invalid command: count must be greater than zero");
                return;
            }
            if (count === 0) {
                delete phase.troopDistribution[target];
            }
            else {
                phase.troopDistribution[target] = count;
            }
        },
        moveTroops: (state, command: PayloadAction<MoveTroops>) => {
            if (state.currentPhase.type !== "move") {
                console.error("Invalid command: Not in movement phase");
                return;
            }
            if (command.payload.count <= 0) {
                console.error("Invalid command: Need to move at least one troop!");
                return;
            }
            if (state.rules.moveLimit !== null && state.currentPhase.numberOfMoves >= state.rules.moveLimit) {
                console.error("Invalid command: Move limit reached");
                return;
            }
            const from = state.nodes[command.payload.from];
            const to = state.nodes[command.payload.to];
            if (from === undefined || to === undefined) {
                console.error("Invalid command: Node does not exist");
                return;
            }
            if (from.owner !== state.currentPlayer
                || to.owner !== state.currentPlayer) {
                console.error("Invalid command: Nodes do not belong to player");
                return;
            }
            const { count } = command.payload;
            if (from.troops <= count) {
                console.error("Invalid command: Not enough troops");
                return;
            }
            to.troops += count;
            from.troops -= count;
            state.currentPhase.numberOfMoves += 1;

            state.turnLog.push({
                type: "move",
                ...command.payload,
            });
        },
        startAttack: (state, command: PayloadAction<MoveTroops>) => {
            if (state.currentPhase.type !== "attack") {
                console.error("Invalid command: Not in movement phase");
                return;
            }
            if (command.payload.count <= 0) {
                console.error("Invalid command: Need to attack with at least one troop!");
                return;
            }
            if (state.rules.attackLimit !== null && state.currentPhase.numberOfAttacks >= state.rules.attackLimit) {
                console.error("Invalid command: Attack limit reached");
                return;
            }
            const from = state.nodes[command.payload.from];
            const to = state.nodes[command.payload.to];
            if (from === undefined || to === undefined) {
                console.error("Invalid command: Node does not exist");
                return;
            }
            if (from.owner !== state.currentPlayer) {
                console.error("Invalid command: Start node does not belong to player");
                return;
            }
            if (to.owner === state.currentPlayer) {
                console.error("Invalid command: Target node belongs to player");
                return;
            }
            const { count } = command.payload;
            if (from.troops <= count) {
                console.error("Invalid command: Not enough troops");
                return;
            }
            state.currentPhase = {
                type: "ongoingAttack",
                from: from.tag,
                to: to.tag,
                remaining: count,
                numberOfAttacks: state.currentPhase.numberOfAttacks + 1,
                hasReceivedCard: state.currentPhase.hasReceivedCard,
            };
            from.troops -= count;

            state.turnLog.push({
                type: "attack",
                from: command.payload.from,
                to: command.payload.to,
                diceRolls: [],
                attackerLosses: 0,
                defenderLosses: 0,
                attackingTroops: command.payload.count,
            });
        },
        endPhase: (state) => {
            switch (state.currentPhase.type) {
                case "place":
                    for (const [node, added] of Object.entries(state.currentPhase.troopDistribution)) {
                        state.nodes[node].troops += added;
                    }
                    state.turnLog.push({
                        type: "placeTroops",
                        placedTroops: state.currentPhase.troopDistribution,
                    });
                    state.currentPhase = { type: "attack", numberOfAttacks: 0, hasReceivedCard: false };
                    break;
                case "attack":
                    state.currentPhase = { type: "move", numberOfMoves: 0 };
                    break;
                case "move":
                    goToNextRound(state);
                    break;
                case "ongoingAttack":
                    applySingleAttack(state);
                    break;
                case "initialPlacement":
                    console.error("Invalid command: Must use placeInitialArmy to end this phase");
                    break;
                default:
                    const never: never = state.currentPhase;
                    throw new Error(`Invalid phase: ${(never as any).type}!`);
            }
        },
        placeInitialArmy: (state, command: PayloadAction<NodeTag>) => {
            if (state.currentPhase.type !== "initialPlacement") {
                console.error("Invalid command: Not in initial placement phase");
                return;
            }

            const node = state.nodes[command.payload];
            if (node.owner !== state.currentPlayer) {
                console.error("Invalid command: Node is not owned by player");
                return;
            }

            node.troops++;

            const playerIndex = state.playerOrder.indexOf(state.currentPlayer);
            let nextPlayerIndex = playerIndex + 1;
            if (nextPlayerIndex >= state.playerOrder.length) {
                state.currentPlayer = state.playerOrder[0];
                const roundsLeft = state.currentPhase.roundsLeft - 1;
                if (roundsLeft === 0) {
                    state.currentPhase = {
                        type: "place",
                        availableTroops: getPlaceableTroops(state, state.currentPlayer),
                        troopDistribution: {}
                    }
                }
                else {
                    state.currentPhase = { type: "initialPlacement", roundsLeft };
                }
            }
            else {
                state.currentPlayer = state.playerOrder[nextPlayerIndex];
            }
        }
    }
});

export const { tradeCards, setPlacedTroops, moveTroops, startAttack, endPhase, placeInitialArmy } = gameSlice.actions;
