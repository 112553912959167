export function websocketUrl() {
    if (process.env.NODE_ENV === "development" && window.location.hostname === "localhost") {
        return "ws://localhost:3001/websocket";
    }
    else {
        const { protocol, host } = window.location;
        const ws = protocol === "https:" ? "wss" : "ws";
        return `${ws}://${host}/websocket`;
    }
}

export function apiBaseUrl() {
    if (process.env.NODE_ENV === "development" && window.location.hostname === "localhost") {
        return "http://localhost:3001"
    } else {
        return "";
    }
}
