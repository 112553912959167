import React from "react";
import ReactDOM from "react-dom";
import "./Tooltip.css"

export function Tooltip(props: {
    children: [tooltipChild: React.ReactNode, ...mainChildren: React.ReactNode[]]
}) {
    const { children: [tooltipChild, ...mainChildren] } = props;
    const [shown, setShown] = React.useState(false);
    const [[mouseX, mouseY], setPosition] = React.useState([0, 0]);

    return <>
        <div
            className="has-tooltip"
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
            onMouseMove={e => setPosition([e.clientX, e.clientY])}
        >
            {mainChildren}
        </div>
        {shown && <ActualTooltip {...{ mouseX, mouseY }}>{tooltipChild}</ActualTooltip>}
    </>
}

export function SvgTooltip(props: {
    children: [tooltipChild: React.ReactNode, ...mainChildren: React.ReactNode[]]
}) {
    const { children: [tooltipChild, ...mainChildren] } = props;
    const [shown, setShown] = React.useState(false);
    const [[mouseX, mouseY], setPosition] = React.useState([0, 0]);

    return <>
        <g
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
            onMouseMove={e => setPosition([e.clientX, e.clientY])}
        >
            {mainChildren}
        </g>
        {shown && <ActualTooltip {...{ mouseX, mouseY }}>{tooltipChild}</ActualTooltip>}
    </>
}

function ActualTooltip(props: { mouseX: number, mouseY: number, children: React.ReactNode }) {
    const { mouseX, mouseY, children } = props;
    const [overflowing, setOverflowing] = React.useState(false);

    let style: React.CSSProperties = {};
    if (!overflowing) {
        style = { left: mouseX + 10, top: mouseY + 5 };
    }
    else {
        style = { left: mouseX + 10, bottom: 3 };
    }

    return ReactDOM.createPortal(
        <div style={style}
            ref={x => {
                if (!x) return;
                const rect = x.getBoundingClientRect();
                const bottom = rect.y + rect.height;
                const parentRect = x.parentElement?.getBoundingClientRect()!;
                const parentBottom = parentRect.y + parentRect.height;
                const overflowing = bottom > parentBottom;
                if (overflowing) setOverflowing(true);
            }}>
            {children}
        </div>, document.getElementById('tooltipOverlay')!);
}
