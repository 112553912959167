import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GameRules, PlayerTag } from "../logic/GameState";
import { playerConnected, playerDisconnected } from "../net/server/serverActions";

interface ColorCombination {
    readonly color: string,
    readonly textColor: string
}

interface LobbyPlayer {
    tag: PlayerTag,
    name: string,
    colorChoice: number,
    portrait: string,
    isReady: boolean
}

export const colorCombinations: readonly ColorCombination[] = Object.freeze([
    {
        color: "royalblue",
        textColor: "white"
    },
    {
        color: "orange",
        textColor: "black"
    },
    {
        color: "forestgreen",
        textColor: "white"
    },
    {
        color: "crimson",
        textColor: "white"
    },
    {
        color: "gold",
        textColor: "black"
    },
    {
        color: "rebeccapurple",
        textColor: "white"
    },
    {
        color: "maroon",
        textColor: "white"
    },
    {
        color: "whitesmoke",
        textColor: "black"
    },
    {
        color: "lightslategray",
        textColor: "white"
    },
    {
        color: "black",
        textColor: "white"
    },
])

export type LobbyState = {
    connectedPlayers: { [tag: PlayerTag]: LobbyPlayer },
    host: PlayerTag,
    rules: GameRules
}

export const lobbySlice = createSlice({
    name: "lobby",
    initialState: {
        connectedPlayers: {} as { [tag: string]: LobbyPlayer },
        host: "",
        rules: {
            attackLimit: 5,
            moveLimit: 8,
        }
    } as LobbyState,
    reducers: {
        setName(state, action: PayloadAction<{ tag: PlayerTag, name: string }>) {
            const { tag, name } = action.payload;
            if (state.connectedPlayers[tag] === undefined) return;
            state.connectedPlayers[tag].name = name;
        },
        setColors(state, action: PayloadAction<{ tag: PlayerTag, colorChoice: number }>) {
            const { tag, colorChoice } = action.payload;
            if (state.connectedPlayers[tag] === undefined) return;
            if (getTakenColors(state).has(colorChoice)) return;
            state.connectedPlayers[tag].colorChoice = colorChoice;
        },
        setPortrait(state, action: PayloadAction<{ tag: PlayerTag, portrait: string }>) {
            const { tag, portrait } = action.payload;
            if (state.connectedPlayers[tag] === undefined) return;
            state.connectedPlayers[tag].portrait = portrait;
        },
        setReady(state, action: PayloadAction<{ tag: PlayerTag, ready: boolean }>) {
            const { tag, ready } = action.payload;
            state.connectedPlayers[tag].isReady = ready;
        },
        startGame() {
            // do nothing - the event is handled elsewhere
        },
        setRule<T extends keyof GameRules>(
            state: LobbyState,
            action: PayloadAction<{ rule: T, value: GameRules[T] }>) {
            const { rule, value } = action.payload;
            state.rules[rule] = value;
        },
    },
    extraReducers: builder => builder
        .addCase(playerConnected, (state, action) => {
            const name = action.payload;

            // get fresh tag for the player
            //TODO: this probably belongs somewhere else
            let tag = name as PlayerTag;
            let i = 1;
            while (tag in state.connectedPlayers) {
                tag = `${name}-${i++}` as PlayerTag;
            }

            // get untaken color set for the player
            const takenColors = getTakenColors(state);
            let colorChoice = 0;
            while (takenColors.has(colorChoice))
                colorChoice++;
            if (colorChoice >= colorCombinations.length)
                return;

            const player: LobbyPlayer = {
                tag,
                name,
                colorChoice,
                portrait: "/portraits/charles.jpg",
                isReady: false
            };
            state.connectedPlayers[player.tag] = player;
            if (Object.keys(state.connectedPlayers).length === 1)
                state.host = player.tag;
        })
        .addCase(playerDisconnected, (state, action) => {
            const player = action.payload;
            delete state.connectedPlayers[player];
            if (player === state.host) {
                state.host = Object.keys(state.connectedPlayers)[0] as PlayerTag;
            }
        })
});

const getTakenColors = (state: LobbyState) =>
    new Set(Object.values(state.connectedPlayers)
        .map(player => player.colorChoice));

export const {
    setName,
    setColors,
    setPortrait,
    setReady,
    startGame,
    setRule,
} = lobbySlice.actions;
