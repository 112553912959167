import React from "react";

export default function InviteLinkBox() {
    const inviteLink = window.location.href.replace(/\/lobby\/?$/, "");
    const copyBoxRef = React.useRef<HTMLElement>(null);
    const [[top, left], setPopupPos] = React.useState([0, 0]);
    return <div className="lobby-invite-box">
        <span>Link to this game:</span>
        <span
            className="lobby-invite-link"
            onClick={e => {
                navigator.clipboard.writeText(inviteLink);
            }}
        >
            {inviteLink}
        </span>
        <button className="lobby-invite-copy-button fancy-button" onClick={async (e) => {
            navigator.clipboard.writeText(inviteLink);

            const copyBox = copyBoxRef.current;
            if (copyBox !== null) {
                copyBox.getAnimations().forEach(a => a.cancel());
                copyBox.animate([
                    { offset: 0, opacity: "0%" },
                    { offset: 0.05, opacity: "100%" },
                    { offset: 0.8, opacity: "100%" },
                    { offset: 1, opacity: "0%" }
                ], {
                    duration: 1000,
                    fill: "forwards"
                })
            }
            const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = e.currentTarget;
            setPopupPos([
                offsetTop + offsetHeight,
                offsetLeft + offsetWidth / 2
            ]);
        }}>
            Copy
        </button>
        <span className="lobby-invite-copied" style={{ top, left }} ref={copyBoxRef}>Copied to clipboard!</span>
    </div>;
}
