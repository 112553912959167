import React from 'react';
import { useAppSelector } from '../store/Store';

export function PingDisplay() {
    const ping = useAppSelector(state => state.mode === "multiplayer"
        && state.connection !== "disconnected" ?
        state.connection.ping : null);
    if (ping === null)
        return <></>;
    return <div className="ping-display">
        {ping.toFixed(0)} ms
    </div>;
}
