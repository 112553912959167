import classNames from "classnames";
import React from "react";
import { GameRules } from "../logic/GameState";
import { useLobbySelector } from "../store/selectorHooks";
import { useAppDispatch } from "../store/Store";
import { setRule } from "./lobbySlice";
import "./LobbySettings.css";


export function LobbySettingsUi() {
    return <div>
        <h2>Settings</h2>
        <ul className="lobby-rules">
            <LimitSettingsUi title="Attack Limit:" rule="attackLimit" defaultValue={5} min={1} />
            <LimitSettingsUi title="Move Limit:" rule="moveLimit" defaultValue={8} min={0} />
        </ul>
    </div>;
}

function LimitSettingsUi(props: {
    title: string,
    rule: keyof GameRules,
    defaultValue: number,
    min: number,
}) {
    const { title, rule, defaultValue, min } = props;
    const value = useLobbySelector(state => state.lobby.rules[rule]);
    const enabled = value !== null;
    const dispatch = useAppDispatch();
    const setEnabled = (newValue: boolean) => {
        if (enabled === newValue) return;
        if (newValue) dispatch(setRule({ rule, value: defaultValue }));
        else dispatch(setRule({ rule, value: null }));
    }

    const isHost = useLobbySelector(state => state.playerTag === state.lobby.host);

    return <li className={classNames({ enabled })}>
        <input type="checkbox" checked={enabled}
            onChange={e => isHost && setEnabled(e.target.checked)}
            disabled={!isHost}
        />
        <span onClick={e => isHost && setEnabled(!enabled)}>
            {title}
        </span>
        <input type="number"
            min={min}
            value={value ?? defaultValue}
            onChange={e => isHost && dispatch(setRule({ rule, value: e.target.valueAsNumber }))}
            readOnly={!isHost}
        />
    </li>
}
