import React from "react";
import { PlayerTag } from "../logic/GameState";
import { PortraitWithTooltip } from "../PortraitWithTooltip";
import { useGameState } from "../store/selectorHooks";
import "./PlayerBox.css"


export default function PlayerBox(props: { player: PlayerTag; children: React.ReactChild | React.ReactChild[]; }) {
    return <div className="player-box">
        <PlayerBoxName player={props.player} />
        <PlayerBoxAvatar player={props.player} />
        <div className="player-box-data">
            {props.children}
        </div>
    </div>;
}
function PlayerBoxName(props: { player: PlayerTag; }) {
    const player = useGameState(state => state.players[props.player]);
    return <div className="player-box-name" style={{
        backgroundColor: player.color,
        color: player.textColor
    }}
    >
        {player.name}
    </div>;
}
function PlayerBoxAvatar(props: { player: PlayerTag; }) {
    const portrait = useGameState(state => state.players[props.player].portrait);
    return <PortraitWithTooltip className="player-box-avatar" src={portrait} />;
}
