import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContinentTag, NodeTag } from '../logic/GameState';
import { addContinent, addNode } from './mapSlice';

type EditorMode =
    | { type: "addNode" }
    | { type: "chooseNodeName", position: [number, number], continent: ContinentTag }
    | { type: "removeNode" }
    | { type: "addEdge" }
    | { type: "removeEdge" }
    | { type: "none" };

type DisplayedEditorMode =
    | "addNode"
    | "removeNode"
    | "addEdge"
    | "removeEdge"
export const displayedModes: { type: DisplayedEditorMode; text: string; }[] = [
    { type: "addNode", text: "Add node" },
    { type: "removeNode", text: "Remove node" },
    { type: "addEdge", text: "Add edge" },
    { type: "removeEdge", text: "Remove edge" },
];

export const modeSlice = createSlice({
    name: "editMode",
    initialState: {
        mode: { type: "none" } as EditorMode,
        lastNode: null as NodeTag | null,
        selectedContinent: null as ContinentTag | null,
    },
    reducers: {
        setMode: (state, action: PayloadAction<EditorMode>) => {
            state.mode = action.payload;
        },
        setLastNode: (state, action: PayloadAction<NodeTag | null>) => {
            state.lastNode = action.payload;
        },
        selectContinent: (state, action: PayloadAction<ContinentTag | null>) => {
            state.selectedContinent = action.payload;
        }
    },
    extraReducers: builder => builder
        .addCase(addContinent, (state, action) => {
            state.selectedContinent = action.payload.tag;
        })
        .addCase(addNode, (state) => {
            if (state.mode.type === "chooseNodeName")
                state.mode = { type: "addNode" }
        })
})
export const { setMode, setLastNode, selectContinent } = modeSlice.actions;
