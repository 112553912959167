import { WritableDraft } from 'immer/dist/internal';
import { GameState } from './GameState';
import { getPlaceableTroops } from './getPlaceableTroops';

export function goToNextRound(state: WritableDraft<GameState>) {
    const playerIndex = state.playerOrder.indexOf(state.currentPlayer);
    const newPlayerIndex = (playerIndex + 1) % state.playerOrder.length;
    state.currentPlayer = state.playerOrder[newPlayerIndex];

    state.currentPhase = {
        type: "place",
        availableTroops: getPlaceableTroops(state, state.currentPlayer),
        troopDistribution: {}
    };
    state.turnLog = [];
}
