import React from "react";
import "./Credits.css"
import portraitUrls, { getMetadata, PortraitMetadata } from "../portraits";
import classNames from "classnames";
import { License, ResourceMetadata, Source } from "./ResourceMetadata";
import { Link } from "react-router-dom";

export function Credits() {
    const ref = React.useRef<HTMLDivElement>(null);
    const [isScrolling, setIsScrolling] = React.useState(true);
    const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
    const [delayedStart] = React.useState(() => performance.now() + 2000)
    React.useEffect(() => {
        let lastScrollValue = 0;
        if (!ref.current) return;
        const element = ref.current;
        ref.current.onwheel = () => setIsScrolling(false);
        lastScrollValue = element.scrollTop;
        const intervalHandle = setInterval(() => {

            if (performance.now() < delayedStart) return;

            if (Math.abs(lastScrollValue - element.scrollTop) > 3) {
                lastScrollValue = element.scrollTop;
                setIsScrolling(false);
            }

            if (isScrolling) {
                element.scrollBy({ top: 1 });
                lastScrollValue = element.scrollTop;
            }
        }, 20);
        return () => clearInterval(intervalHandle);
    }, [isScrolling, setIsScrolling, delayedStart]);
    return <div className="credits-wrapper" ref={ref}
        onWheel={() => setIsScrolling(false)}
        onScroll={(e) => {
            const isScrolledToBottom = e.currentTarget.clientHeight + e.currentTarget.scrollTop === e.currentTarget.scrollHeight;
            setIsScrolledToBottom(isScrolledToBottom);
        }}
    >
        <div className="credits">

            <p className="credits-entry-fullscreen">
                <h1>Bott Kaiser</h1>
                <small>Created by</small>
                Georg Wicke-Arndt
            </p>

            <h2>Image Credits</h2>

            <ImageCreditEntry url="logo.svg" />
            <ImageCreditEntry url="worldMap.svg" />
            {portraitUrls.map(x => <ImageCreditEntry key={x} url={x} />)}
            <ImageCreditEntry url="buttons.png" />
            <ImageCreditEntry url="frame.png" />


            <p className="credits-entry-halfscreen"></p>

            <h2>Sound Credits</h2>

            <SoundCreditEntry url="/sounds/clack.mp3" />
            <SoundCreditEntry url="/sounds/click_low.mp3" />
            <SoundCreditEntry url="/sounds/click.mp3" />
            <SoundCreditEntry url="/sounds/fanfare_attack.mp3" />
            <SoundCreditEntry url="/sounds/fanfare_attack_2.mp3" />
            <SoundCreditEntry url="/sounds/fanfare_attack_3.mp3" />
            <SoundCreditEntry url="/sounds/move_1.mp3" />
            <SoundCreditEntry url="/sounds/move_2.mp3" />
            <SoundCreditEntry url="/sounds/move_3.mp3" />
            <SoundCreditEntry url="/sounds/move_4.mp3" />
            <SoundCreditEntry url="/sounds/move_5.mp3" />
            <SoundCreditEntry url="/sounds/ping.mp3" />
            <SoundCreditEntry url="/sounds/ping_high.mp3" />
            <SoundCreditEntry url="/sounds/tick.mp3" />
            <SoundCreditEntry url="/sounds/trade_cards.mp3" />

            <p className="credits-entry-fullscreen">
                <h2>Special Thanks to</h2>

                <p>
                    Die Wochenendräuber
                    <small>for testing, in particular</small>
                    <br />
                    Benjamin Roth
                    <br />
                    Marc Fassbender
                </p>
            </p>

            <div className="button-stack">
                <Link to="/" className="fancy-button">Back to Main Menu</Link>
                <a className="fancy-button" href="https://wcke.de">Visit the Author's Website</a>
            </div>

        </div>

        <div className={classNames(
            "credits-auto-scroll-button",
            (isScrolling || isScrolledToBottom) && "hidden")}
            onClick={() => setIsScrolling(true)}
        >
            ↓
        </div>
    </div >
}

function ImageCreditEntry(props: { url: string }) {
    const [metadata, setMetadata] = React.useState<PortraitMetadata>();

    React.useEffect(() => {
        getMetadata(props.url)
            .then(x => setMetadata(x));
    });

    if (!metadata)
        return <p>Loading...</p>;

    return <div className="credits-entry-image">
        <img src={props.url} width={96} alt={metadata.name} />
        <div>
            <div className="credits-entry-name">"{metadata.name}"</div>
            <CreditsMetadataDisplay metadata={metadata} />
        </div>
    </div>
}

function SoundCreditEntry(props: { url: string }) {
    const [metadata, setMetadata] = React.useState<PortraitMetadata>();

    React.useEffect(() => {
        getMetadata(props.url)
            .then(x => setMetadata(x));
    });

    const audioRef = React.useRef<HTMLAudioElement>(null);

    if (!metadata)
        return <p>Loading...</p>;

    return <div className="credits-entry-sound">
        <div className="credits-entry-sound-play" onClick={() => audioRef.current?.play()}>▶️</div>
        <div className="credits-entry-name">
            <a href={metadata.url}>
                "{/\/([^/]*)$/.exec(metadata.url)?.at(-1) ?? metadata.url}"
            </a>
        </div>
        <audio src={props.url} ref={audioRef} />
        <CreditsMetadataDisplay metadata={metadata} />
    </div>
}

function CreditsMetadataDisplay(props: { metadata: ResourceMetadata }) {
    const { metadata } = props;
    return <div className="credits-entry-metadata">
        <div>{metadata.credits}</div>
        <SourcesDisplay sources={metadata.sources} />
        <div>License: <LicenseDisplay license={metadata.license} /></div>
    </div>
}

function SourcesDisplay(props: { sources: Source[] }) {
    const { sources } = props;
    if (sources.length === 1) {
        const [source] = sources;
        return <div>
            <SourceDisplay source={source} />
        </div>
    }
    return <div>
        Sources:
        <ul>
            {
                sources.map((source, i) => <li key={i}>
                    <SourceDisplay source={source} />
                </li>)
            }
        </ul>
    </div>
}

function SourceDisplay(props: { source: Source }) {
    const { source } = props;
    if (source === "OwnWork")
        return <>Own Work</>
    return <>
        <a href={source.url}>{source.url}</a>
        <br />
        Source License: <LicenseDisplay license={source.license} />
    </>
}

function LicenseDisplay(props: { license: License }) {
    const { license } = props;

    if (license === "PublicDomain")
        return <span>Public Domain</span>
    else
        return <a href={license.link}>{license.name}</a>
}
