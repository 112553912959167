import React from 'react';
import { Edges } from './Edges';
import { useAppDispatch } from '../store/Store';
import { deselect } from './mapSelectionSlice';
import { HintsDisplay } from './HintsDisplay';
import { MapUi } from './MapUi';
import { Nodes } from './Nodes';
import { useGameState } from "../store/selectorHooks";
import NextPhaseButton from './NextPhaseButton';
import { PingDisplay } from './PingDisplay';
import PlayerInfo from './PlayerInfo';
import { Sounds } from './Sounds';
import { EndGameOverlay } from './EndGameOverlay';
import { HistoryControls } from './HistoryControls';

export const ActualSvgSizeContext = React.createContext<[number, number]>([1, 1]);

export function Game() {
  const dispatch = useAppDispatch();

  const { image, width, height } = useGameState(state => state.baseMap);
  const [actualSize, setActualSize] = React.useState<[number, number]>([width, height]);
  const ref = React.useRef<SVGSVGElement>(null);
  React.useLayoutEffect(() => {
    const onResize = () => {
      const rect = ref.current?.getBoundingClientRect();
      if (rect) {
        const { width, height } = rect;
        setActualSize([width, height]);
      }
    };
    const observer = new ResizeObserver(onResize);
    observer.observe(ref.current!);
  }, []);

  const isGameOver = useGameState(state => state.playerOrder.length === 1);

  return (
    <div className="App">
      <Sounds />
      <PingDisplay />
      <PlayerInfo />
      <svg className="map-canvas"
        onContextMenu={e => e.preventDefault()}
        style={{ aspectRatio: `${width} / ${height}` }}
        ref={ref}
      >
        <ActualSvgSizeContext.Provider value={actualSize}>
          <image
            onClick={e => dispatch(deselect())}
            href={image} height="100%" />
          <Edges />
          <Nodes />
          {/* We're using a foreignObject for the overlays, since that allows us
            to embed the SVG directly in the parent grid, which in turn allows us 
            to center / size it more easily.
            */}
          <foreignObject x="0" y="0" width="100%" height="100%">
            <MapUi />
          </foreignObject>
        </ActualSvgSizeContext.Provider>
      </svg>
      <HintsDisplay />
      <div className="game-column-right">
        <HistoryControls />
        <NextPhaseButton />
      </div>
      {/* If the game is over, we need to display a "win" screen. */}
      {isGameOver && <EndGameOverlay />}
    </div>
  );
}
