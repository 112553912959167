import React from 'react';
import { Link } from 'react-router-dom';
import "./Menu.css"

export function Menu() {
  return <div className="main-menu">
    <h1>Bott-Kaiser</h1>
    <div className="main-menu-buttons">
      <Link className="fancy-button" to="/game/new">New Game</Link>
      <Link className="fancy-button" to="/game">Demo (singleplayer)</Link>
      <Link className="fancy-button" to="/editor">Map editor</Link>
      <Link className="fancy-button" to="/credits">Credits</Link>
      <Link className="fancy-button" to="/imprint">Imprint</Link>
    </div>
  </div>;
}
