import React from 'react';
import { Link } from 'react-router-dom';
import { useGameState } from "../store/selectorHooks";
import PlayerBox from "./PlayerBox";
import "./EndGameOverlay.css"

const medals = {
    1: "🥇",
    2: "🥈",
    3: "🥉",
}

export function EndGameOverlay() {
    const playersByPlace = useGameState(state =>
        Object.values(state.players).sort((a, b) => a.place! - b.place!));

    const [winner, ...others] = playersByPlace;

    return <div className="end-game-overlay-wrapper">
        <div className="end-game-overlay">
            <h1>
                Game Over
            </h1>

            <div className="end-game-player-list end-game-player-list-1">
                <PlayerBox player={winner.tag}>
                    {medals[1]}
                </PlayerBox>
            </div>


            <div className="end-game-player-list end-game-player-list-23">
                {others.slice(0, 2).map((player, i) => <PlayerBox player={player.tag} key={i}>
                    {medals[(i + 2) as 2 | 3]}
                </PlayerBox>)}
            </div>

            <div className="end-game-player-list end-game-player-list-n">
                {others.slice(2).map((player, i) => <PlayerBox player={player.tag} key={i}>
                    {i + 4}
                </PlayerBox>)}
            </div>

            <div className="button-stack">
                <Link to="/credits" className="fancy-button">
                    Credits
                </Link>

                <Link to="/" className="fancy-button">
                    Back to Main Menu
                </Link>
            </div>
        </div>
    </div>
}
