import { Continent, GameMap, MapNode, NodeTag } from '../logic/GameState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import getTag from "./getTag";
import initialGameState from '../logic/worldMap';
export const mapSlice = createSlice({
  name: "map",
  initialState: {
    baseMap: {
      image: "/worldMap.svg",
      width: 749.81909,
      height: 519.06781,
    },
    nodes: {},
    edges: [],
    continents: {}
  } as GameMap,
  reducers: {
    addNode: (state, action: PayloadAction<MapNode>) => {
      let { tag } = action.payload;
      while (state.nodes[tag] !== undefined)
        tag = getTag();
      state.nodes[tag] = { ...action.payload, tag };
    },
    addEdge: (state, action: PayloadAction<[NodeTag, NodeTag]>) => {
      const [newA, newB] = action.payload;
      const existingEdge = state.edges.find(([a, b]) => (a === newA && b === newB)
        || (a === newB && b === newA));
      if (existingEdge === undefined)
        state.edges.push(action.payload);
    },
    removeNode: (state, action: PayloadAction<NodeTag>) => {
      const node = action.payload;
      delete state.nodes[node];
      state.edges = state.edges.filter(([a, b]) => a !== node && b !== node);
    },
    removeEdge: (state, action: PayloadAction<[NodeTag, NodeTag]>) => {
      const [removedA, removedB] = action.payload;
      state.edges = state.edges.filter(([a, b]) => {
        if (a === removedA && b === removedB)
          return false;
        if (b === removedA && a === removedB)
          return false;
        return true;
      });
    },
    addContinent: (state, action: PayloadAction<Continent>) => {
      let { tag } = action.payload;
      if (state.continents[tag] === undefined)
        state.continents[tag] = { ...action.payload, tag };
    },
    loadWorldMap: () => initialGameState,
  }
});

export const { addNode, addEdge, removeNode, removeEdge, addContinent, loadWorldMap } = mapSlice.actions;

