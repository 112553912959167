
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import produce from 'immer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { gameSlice } from '../logic/gameSlice';
import GameClient from '../net/client';
import { connectionSlice } from '../net/connectionSlice';
import { AppState, ConnectingMode, EditorMode, GameModeBase, LobbyMode, MultiplayerMode, SingleplayerMode } from './AppState';
import { mapSelectionSlice, validateSelection } from '../game/mapSelectionSlice';
import { mapSlice } from '../editor/mapSlice';
import { modeSlice } from '../editor/editModeSlice';
import { Reducer } from 'react';
import initializeExampleGame from '../logic/exampleGame';
import applyFullStateTransition from './applyFullStateTransition';
import { getHistoryAction, historySlice } from '../logic/historySlice';
import { initSingleplayerGame, initEditor, initChooseName, receiveState, joinGame, serverInit } from './specialActions';

let activeClient: GameClient | null = null;
export const setNetClient = (client: GameClient | null) => {
    activeClient = client;
    if (process.env.NODE_ENV === "development")
        (window as any).activeClient = client;
};

const singleplayerBaseReducer = combineReducers({
    game: gameSlice.reducer,
    history: historySlice.reducer,
    selection: mapSelectionSlice.reducer,
});

const singleplayerReducer: Reducer<SingleplayerMode, AnyAction> = (state, action) => {
    const baseState = {
        game: state.game,
        selection: state.selection,
        history: state.history
    };
    state = {
        ...state,
        ...singleplayerBaseReducer(baseState, action),
    };
    state = produce(state, state => {
        state.selection = validateSelection(state.game, state.selection, true);
    });

    return state;
}

const editorBaseReducer = combineReducers({
    map: mapSlice.reducer,
    editMode: modeSlice.reducer,
});
const editorReducer: Reducer<EditorMode, AnyAction> = (state, action) => {
    const baseState = { map: state.map, editMode: state.editMode };
    return { mode: state.mode, ...editorBaseReducer(baseState, action) };
}

const connectingBaseReducer = combineReducers({
    connection: connectionSlice.reducer,
});
const connectingReducer: Reducer<ConnectingMode, AnyAction> = (state, action) => {
    const baseState = { connection: state.connection };
    return { ...state, ...connectingBaseReducer(baseState, action) };
};

const lobbyBaseReducer = combineReducers({
    connection: connectionSlice.reducer,
});
const lobbyReducer: Reducer<LobbyMode, AnyAction> = (state, action) => {
    if ((action.type as string).startsWith("lobby/")) {
        activeClient!.sendAction(action);
        return state;
    }
    const baseState = { connection: state.connection };
    return { ...state, ...lobbyBaseReducer(baseState, action) };
};

const multiplayerBaseReducer = combineReducers({
    selection: mapSelectionSlice.reducer,
    connection: connectionSlice.reducer,
    history: historySlice.reducer,
});
const multiplayerReducer: Reducer<MultiplayerMode, AnyAction> = (state, action) => {
    if ((action.type as string).startsWith("game/")) {
        activeClient!.sendAction(action);
        return state;
    }
    const baseState = { connection: state.connection, selection: state.selection, history: state.history };
    state = { ...state, ...multiplayerBaseReducer(baseState, action) };
    state = produce(state, state => {
        state.selection = validateSelection(state.game, state.selection, state.playerTag === state.game.currentPlayer);
    });
    return state;
};

function reduce(state: AppState | undefined, action: AnyAction): AppState {
    const oldState = state;
    if (state === undefined) {
        state = { mode: "menu" };
    }
    if (action.type === initSingleplayerGame.type) {
        state = {
            mode: "singleplayer",
            game: initializeExampleGame(),
            selection: mapSelectionSlice.reducer(undefined, action),
            history: historySlice.reducer(undefined, action),
        };
    }
    else if (action.type === initEditor.type) {
        state = {
            mode: "editor",
            ...editorBaseReducer(undefined, action)
        };
    }
    else if (action.type === initChooseName.type) {
        const gameInfo = (action as ReturnType<typeof initChooseName>).payload;
        state = {
            mode: "chooseName",
            gameInfo,
            connection: connectionSlice.reducer(undefined, action)
        };
    }
    else if (action.type === receiveState.type) {
        state = applyFullStateTransition(state, action.payload);
    }
    else if (action.type === joinGame.type) {
        state = {
            mode: "connecting",
            connection: connectionSlice.reducer(undefined, joinGame),
            playerTag: action.payload.name
        }
    }
    else if (action.type === serverInit.type) {
        const _action = action as ReturnType<typeof serverInit>;
        state = applyFullStateTransition(state, _action.payload.initialState);
    }
    state = modeReducer(state, action);

    // save state in history if applicable
    const wasInGame = oldState?.mode === "singleplayer" || oldState?.mode === "multiplayer";
    const isInGame = state.mode === "singleplayer" || state.mode === "multiplayer";
    if (wasInGame && isInGame) {
        const historyAction = getHistoryAction((oldState as GameModeBase).game, (state as GameModeBase).game);
        if (historyAction !== undefined) {
            state = {
                ...state,
                history: historySlice.reducer((state as any).history, historyAction)
            } as AppState;
        }
    }

    return state;
}

function modeReducer(state: AppState, action: AnyAction) {
    switch (state.mode) {
        case "editor":
            return editorReducer(state, action);
        case "lobby":
            return lobbyReducer(state, action);
        case "multiplayer":
            return multiplayerReducer(state, action);
        case "singleplayer":
            return singleplayerReducer(state, action);
        case "connecting":
            return connectingReducer(state, action);
        case "chooseName":
        case "menu":
            return state;
        default:
            const unreachableState: never = state;
            throw new Error(`Invalid mode: ${(unreachableState as any).mode}`);
    }
}

export const store = configureStore({
    reducer: reduce,
    devTools: {
        actionsBlacklist: ["connection/receivePing"]
    }
})

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
