import React from "react";
import button1 from "./button-1.png";
import button2 from "./button-2.png";
import button3 from "./button-3.png";

function ImagePreload(props: { src: string }) {
    const [, setPreloaded] = React.useState<HTMLImageElement>();
    React.useEffect(() => {
        const img = new Image();
        img.src = props.src;
        setPreloaded(img);
    }, [props.src, setPreloaded]);
    return <></>
}

export default function FancyButtonPreload() {
    return <>
        <ImagePreload src={button1} />
        <ImagePreload src={button2} />
        <ImagePreload src={button3} />
    </>
}