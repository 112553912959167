import classNames from "classnames";
import React from "react";
import Overlay from "../ui-utils/Overlay";
import "./Dropdown.css";

export default function Dropdown(props: { children: [label: React.ReactNode, ...choices: React.ReactNode[]]; }) {
    const [open, setOpen] = React.useState(false);
    const [first, ...rest] = props.children;
    const firstRef = React.useRef<HTMLDivElement>(null);
    let top = 0, left = 0;
    if (open && firstRef.current) {
        const rect = firstRef.current.getBoundingClientRect();
        top = rect.top + rect.height;
        left = rect.left;
    }
    return <>
        <div className={classNames("dropdown-button", { "dropdown-open": open })} ref={firstRef} onClick={() => setOpen(true)}>
            ⯆{first}
        </div>
        {open && <Overlay onBackgroundClicked={() => setOpen(false)}>
            <div className="dropdown-list" style={{ top, left }} onClick={() => {
                setOpen(false)
            }}>
                {rest}
            </div>
        </Overlay>}
    </>;
}
