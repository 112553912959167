import worldMap from './worldMap';
import { GameState, NoCards, PlayerTag } from './GameState';

export default function initializeExampleGame(): GameState {
  const player1 = "player1" as PlayerTag;
  const player2 = "player2" as PlayerTag;
  const nodes = Object.values(worldMap.nodes).map(node => ({
    ...node,
    owner: (node.tag.charCodeAt(0) > "h".charCodeAt(0) ? player1 : player2),
    troops: 10
  }));
  return {
    ...worldMap,
    nodes: Object.fromEntries(nodes.map(node => [node.tag, node])),
    players: {
      [player1]: {
        tag: player1,
        color: "royalblue",
        textColor: "white",
        name: "Napoléon",
        cards: NoCards,
        portrait: "/portraits/napoleon.jpg",
      },
      [player2]: {
        tag: player2,
        color: "orange",
        textColor: "black",
        portrait: "/portraits/bismark.jpg",
        name: "Otto von Bismark",
        cards: { 1: 1, 2: 1, 3: 1 },
      },
    },
    playerOrder: [player1, player2],
    currentPlayer: player1,
    currentPhase: { type: "initialPlacement", roundsLeft: 1 },
    rules: {
      attackLimit: 5,
      moveLimit: 8,
    },
    turnLog: [],
  };
}
