import React from 'react';
import ReactDOM from 'react-dom';
import "./Overlay.css"

export default function Overlay(props: {
    children: React.ReactNode;
    onBackgroundClicked?: () => void
}) {
    const content = <>
        <div
            className="overlayBackground"
            onClick={props.onBackgroundClicked}
        />
        {props.children}
    </>;
    return ReactDOM.createPortal(content, document.getElementById('overlay')!);
}
