import classNames from "classnames";
import React from "react";
import { useLobbySelector } from "../store/selectorHooks";
import { useAppDispatch } from "../store/Store";
import InviteLinkBox from "./InviteLinkBox";
import "./Lobby.css";
import { LobbySettingsUi } from "./LobbySettingsUi";
import { setReady, startGame } from "./lobbySlice";
import PlayerList from "./PlayerList";

export function Lobby() {
    const showStartGameButton = useLobbySelector(state => state.lobby.host === state.playerTag);

    return <div className="lobby-wrapper">
        <div className="lobby">
            <h1 className="lobby-title">Bott-Kaiser &ensp;&ndash;&ensp; Lobby</h1>

            <div className="lobby-map-container">
                <img className="lobby-map" src="/worldMap.svg" alt="World Map" />
                <div>Map: World</div>
            </div>

            <PlayerList />

            <InviteLinkBox />

            <div className="lobby-rest">
                <LobbySettingsUi />

                <div className="lobby-main-button-area">
                    <ReadyButton />
                    {showStartGameButton && <StartGameButton />}
                </div>
            </div>

        </div>
    </div>
}

export function StartGameButton() {
    const allPlayersReady = useLobbySelector(state =>
        Object.values(state.lobby.connectedPlayers)
            .every(player => player.isReady));
    const dispatch = useAppDispatch();

    return <button className={classNames(
        "lobby-main-button fancy-button",
        "lobby-start-game",
        { "lobby-start-game-enabled": allPlayersReady })}
        onClick={() => dispatch(startGame())}
    >
        Start Game
    </button>
}
export function ReadyButton() {
    const isReady = useLobbySelector(state => state.lobby.connectedPlayers[state.playerTag].isReady);
    const self = useLobbySelector(state => state.playerTag);
    const dispatch = useAppDispatch();

    if (isReady)
        return <button
            className="lobby-main-button fancy-button lobby-button-ready"
            onClick={() => dispatch(setReady({ tag: self, ready: false }))}>
            Ready
        </button>
    else
        return <button
            className="lobby-main-button fancy-button lobby-button-not-ready"
            onClick={() => dispatch(setReady({ tag: self, ready: true }))}>
            Not Ready
        </button>
}
