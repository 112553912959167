import React from "react";
import { AttackLogEntry } from "../logic/TurnLog";
import { useGameSelector } from "../store/selectorHooks";
import { useAppSelector } from "../store/Store";


export function Sounds() {
    const currentPhase = useGameSelector(state => state.game.currentPhase);
    const initialPlacement = useGameSelector(state => state.game.currentPhase.type === "initialPlacement");
    const inMovementPhase = useGameSelector(state => state.game.currentPhase.type === "move");

    const selectionType = useGameSelector(state => state.selection.type);
    return <>
        {initialPlacement ? <InitialPlacementSound /> : <ActionSound />}
        {currentPhase.type === "place" &&
            Object.entries(currentPhase.troopDistribution).map(([node, troops]) =>
                <React.Fragment key={node}>
                    <Sound src="/sounds/clack.mp3" token={troops} volume={0.1} />
                    <RemovalSound src="/sounds/clack.mp3" volume={0.1} />
                </React.Fragment>
            )
        }
        {inMovementPhase && <Sound src="/sounds/clack.mp3" />}
        {selectionType !== "noneSelected" && <Sound src="/sounds/tick.mp3" token={selectionType} />}
    </>
}

function InitialPlacementSound() {
    const currentPlayer = useGameSelector(state => state.game.currentPlayer);
    return <RemovalSound key="initialPlacement" src="/sounds/clack.mp3" token={currentPlayer} />
}

function TurnStartSound() {
    const currentPlayer = useGameSelector(state => state.game.currentPlayer);
    const isCurrentPlayer = useAppSelector(state => state.mode === "multiplayer" && state.playerTag === state.game.currentPlayer);
    if (isCurrentPlayer)
        return <Sound key="startTurn" src="/sounds/ping_high.mp3" token={currentPlayer} />
    else
        return <Sound key="startTurn" src="/sounds/ping.mp3" token={currentPlayer} />
}

function ActionSound() {
    const [turnLogLength, lastAction] = useGameSelector(state => [state.game.turnLog.length, state.game.turnLog.at(-1)]);
    switch (lastAction?.type) {
        case "tradeCards":
            return <Sound src="/sounds/trade_cards.mp3" />
        case "attack":
            return <AttackSound action={lastAction} instanceCounter={turnLogLength} />
        case "move":
            const move_sounds = [
                "/sounds/move_1.mp3",
                "/sounds/move_2.mp3",
                "/sounds/move_3.mp3",
                "/sounds/move_4.mp3",
                "/sounds/move_5.mp3",
            ]
            return <Sound src={move_sounds[turnLogLength % move_sounds.length]} />;
        case "placeTroops":
            return <Sound src="/sounds/clack.mp3" />;
        case undefined:
            return <TurnStartSound />
        default:
            return <Sound src="/sounds/click.mp3" />
    }
}

function AttackSound(props: { action: AttackLogEntry, instanceCounter: number }) {
    const { action, instanceCounter } = props;

    const lastRound = action.diceRolls.at(-1);
    const fanfare_sounds = [
        "/sounds/fanfare_attack.mp3",
        "/sounds/fanfare_attack_2.mp3",
        "/sounds/fanfare_attack_3.mp3",
    ];

    if (lastRound === undefined)
        return <Sound src={fanfare_sounds[instanceCounter % fanfare_sounds.length]} />;

    const attackerLost = lastRound.attacker.losses > 0;
    const defenderLost = lastRound.defender.losses > 0;
    if (attackerLost && defenderLost)
        return <Sound src="/sounds/click_low.mp3" token={action.diceRolls.length} />
    else if (attackerLost)
        return <Sound src="/sounds/click_very_low.mp3" token={action.diceRolls.length} />
    else
        return <Sound src="/sounds/click.mp3" token={action.diceRolls.length} />
}

/**
 * This component plays a sound once it is added to the DOM.
 */
function Sound(props: { src: string, volume?: number, token?: string | number }) {
    const { src, token, volume } = props;
    React.useEffect(() => {
        const audio = new Audio(src);
        if (volume)
            audio.volume = volume;
        audio.play();
        return () => audio.remove();
    }, [src, token, volume])
    return <></>;
}

/**
 * This component plays a sound once it is removed from the DOM.
 */
function RemovalSound(props: { src: string, volume?: number, token?: string | number }) {
    const { src, token, volume } = props;
    React.useEffect(() => {
        return () => {
            const audio = new Audio(src);
            if (volume)
                audio.volume = volume;
            audio.play();
        };
    }, [src, token, volume])
    return <></>;
}
