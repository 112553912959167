import { compare, Operation } from "fast-json-patch";
import { applyPatches } from "immer";
import { GameState } from "../logic/GameState";
import jsonPatchesToImmerPatches from "./jsonPatchesToImmerPatches";

export type CompressedHistory = null | {
    reverseDiffs: Operation[][],
    lastState: GameState,
}

export function addCompressedState(state: CompressedHistory, newGameState: GameState): CompressedHistory {
    if (state === null)
        return { reverseDiffs: [], lastState: newGameState }
    const reverseDiff = compare(newGameState, state.lastState)
    return {
        reverseDiffs: [reverseDiff, ...state.reverseDiffs],
        lastState: newGameState,
    }
}

export function decompress(compressed: CompressedHistory): GameState[] {
    if (compressed === null)
        return [];

    const result = [];
    result.push(compressed.lastState);
    let { lastState } = compressed;
    for (const reverseDiff of compressed.reverseDiffs) {
        const immerPatches = jsonPatchesToImmerPatches(reverseDiff);
        const decompressed = applyPatches(lastState, immerPatches);
        result.push(decompressed);
        lastState = decompressed;
    }
    return result.reverse();
}
