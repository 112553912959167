
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isAdjacent } from '../logic/adjacency';
import { GameState, NodeTag } from '../logic/GameState';


export type SelectionState = NoneSelected | FirstSelected | SecondSelected;
export interface NoneSelected {
    type: "noneSelected";
    hovered?: NodeTag;
}
export interface FirstSelected {
    type: "firstSelected";
    first: NodeTag;
    hovered?: NodeTag;
}
export interface SecondSelected {
    type: "bothSelected";
    first: NodeTag;
    second: NodeTag;
}

export const mapSelectionSlice = createSlice({
    name: "mapSelection",
    initialState: { type: "noneSelected" } as SelectionState,
    reducers: {
        selectNode(state, command: PayloadAction<NodeTag>) {
            const tag = command.payload;
            if (state.type === "noneSelected")
                return { type: "firstSelected", first: tag }
            else if (state.type === "firstSelected") {
                if (tag === state.first)
                    return { type: "noneSelected" };
                else
                    return { type: "bothSelected", first: state.first, second: tag };
            }
            return { type: "noneSelected" };
        },
        deselect: (state) => ({ type: "noneSelected" })
    }
})

export function validateSelection(gameState: GameState, uiState: SelectionState, isMyTurn: boolean): SelectionState {
    if (isValidSelection(gameState, uiState) && isMyTurn)
        return uiState;
    return { type: "noneSelected" }
}

function isValidSelection(gameState: GameState, uiState: SelectionState): boolean {
    const currentPhase = gameState.currentPhase.type;
    if (uiState.type === "noneSelected")
        return true;

    if (currentPhase !== "move" && currentPhase !== "attack")
        return false;


    const firstOwner = gameState.nodes[uiState.first].owner;
    if (firstOwner !== gameState.currentPlayer)
        return false;

    if (currentPhase === "move") {
        const limit = gameState.rules.moveLimit;
        if (limit !== null && gameState.currentPhase.numberOfMoves >= limit)
            return false;
    }
    else if (currentPhase === "attack") {
        const limit = gameState.rules.attackLimit;
        if (limit !== null && gameState.currentPhase.numberOfAttacks >= limit)
            return false;
    }

    if (uiState.type === "firstSelected")
        return true;

    if (uiState.type === "bothSelected") {
        const secondOwner = gameState.nodes[uiState.second].owner;
        if (currentPhase === "move" && secondOwner !== gameState.currentPlayer)
            return false
        if (currentPhase === "attack" && secondOwner === gameState.currentPlayer)
            return false;
        return isAdjacent(gameState.edges, uiState.first, uiState.second);
    }
    console.error("Unknown selection state: ", (uiState as any).type);
    return false;
}

export const { selectNode, deselect } = mapSelectionSlice.actions;
