import classNames from 'classnames';
import React from 'react';
import { endPhase } from '../logic/gameSlice';
import { useGameSelector } from "../store/selectorHooks";
import { useAppDispatch } from '../store/Store';
import "./NextPhaseButton.css"
import useIsMyTurn from './useIsMyTurn';

export default function NextPhaseButton() {
    const currentPhase = useGameSelector(state => state.game.currentPhase.type);
    const isMyTurn = useIsMyTurn();

    const dispatch = useAppDispatch();

    let flavour: string;
    switch (currentPhase) {
        case "place":
            flavour = "To Attacks"; break;
        case "attack":
            flavour = "To Movements"; break;
        case "ongoingAttack":
            flavour = "Attack"; break;
        case "move":
            flavour = "End Turn"; break;
        case "initialPlacement":
            flavour = "(unavaliable)"; break;
        default:
            const never: never = currentPhase;
            throw new Error("Unknown phase: " + never as string);
    }

    return <div
        className={classNames(
            "fancy-button",
            "next-phase-button",
            { "next-phase-button-hidden": !isMyTurn || currentPhase === "initialPlacement" })
        }
        onClick={() => dispatch(endPhase())}
    >
        <div className="next-phase-button-arrow">
            →
        </div>
        <div className="next-phase-button-flavour">
            {flavour}
        </div>
    </div>;
}
