import _ from 'lodash';
import { GameState, PlayerTag } from './GameState';
import nodesByOwner from './nodesByOwner';


export function getPlaceableTroops(state: GameState, player: PlayerTag) {
    const ownedTerritories = nodesByOwner(state.nodes)[player].length;
    const troopsFromTerritories = Math.max(Math.floor(ownedTerritories / 3), 3);

    const continentTerritories = _.groupBy(Object.values(state.nodes), node => node.continent);
    const fullyOwnedContinents = Object.entries(continentTerritories)
        .filter(([continent, nodes]) => nodes.every(node => node.owner === player))
        .map(([continent]) => state.continents[continent]);
    const troopsFromContinents = fullyOwnedContinents
        .map(c => c.bonusTroops)
        .reduce(_.add, 0);

    return troopsFromTerritories + troopsFromContinents;
}
