import classNames from "classnames";
import { goTo } from "../logic/historySlice";
import { useGameSelector } from "../store/selectorHooks";
import { useAppDispatch } from "../store/Store";
import "./HistoryControls.css"

export function HistoryControls() {
    const currentState = useGameSelector(state => state.history.selected);
    const numStates = useGameSelector(state => state.history.previousGameStates.length);
    const dispatch = useAppDispatch();

    const canGoBack = currentState === "current" ? numStates > 0 : currentState > 0;
    const canGoForward = currentState !== "current";

    const goBack = () => dispatch(goTo(currentState === "current" ? numStates - 1 : currentState - 1));
    const goForward = () => {
        if (currentState === "current") return;
        else if (currentState === numStates - 1) dispatch(goTo("current"));
        else dispatch(goTo(currentState + 1));
    }

    return <div className="history-controls">
        <button onClick={goBack} className={classNames({ "hidden": !canGoBack })}>
            ←
        </button>
        <button onClick={goForward} className={classNames({ "hidden": !canGoForward })}>
            →
        </button>
        <button onClick={() => dispatch(goTo("current"))} className={classNames({ "hidden": !canGoForward })}>
            ⇥
        </button>
        <br />
        {currentState !== "current" &&
            <>Viewing turn {currentState + 1} / {numStates}</>}
    </div>
}