import _ from 'lodash';
import { WritableDraft } from 'immer/dist/internal';
import { GameState, totalCards } from './GameState';
import nodesByOwner from './nodesByOwner';
import { current } from '@reduxjs/toolkit';
import { rollFightDice } from './fightDice';

export function applySingleAttack(state: WritableDraft<GameState>) {
    if (state.currentPhase.type !== "ongoingAttack")
        throw new Error();

    const logEntry = state.turnLog.at(-1);
    if (logEntry?.type !== "attack")
        throw new Error();

    const phase = state.currentPhase;
    // const from = state.nodes[phase.from];
    const to = state.nodes[phase.to];


    const attackingTroops = Math.min(phase.remaining, 3);
    const defendingTroops = Math.min(to.troops, 2);

    const fightResult = rollFightDice(attackingTroops, defendingTroops);

    logEntry.diceRolls.push(fightResult);
    logEntry.attackerLosses += fightResult.attacker.losses;
    logEntry.defenderLosses += fightResult.defender.losses;

    phase.remaining -= fightResult.attacker.losses;
    to.troops -= fightResult.defender.losses;

    const { hasReceivedCard, numberOfAttacks } = phase;
    if (to.troops <= 0) {
        const winner = state.players[state.currentPlayer];
        const looser = state.players[to.owner];

        // transfer troops
        to.owner = winner.tag;
        to.troops = phase.remaining;
        state.currentPhase = { type: "attack", numberOfAttacks, hasReceivedCard: true };

        // give the player a card
        if (totalCards(winner.cards) < 5 && !hasReceivedCard) {
            const cardType = _.random(1, 3) as 1 | 2 | 3;
            winner.cards[cardType] += 1;
        }

        // check if we need to eliminate the loosing player
        if ((nodesByOwner(current(state.nodes))[looser.tag]?.length ?? 0) === 0) {
            const oldOwnerIndex = state.playerOrder.indexOf(looser.tag);
            state.playerOrder.splice(oldOwnerIndex, 1);

            // give the looser's cards to the winner
            winner.cards = {
                1: winner.cards[1] + looser.cards[1],
                2: winner.cards[2] + looser.cards[2],
                3: winner.cards[3] + looser.cards[3],
            }

            // get the number of remaining players
            const remainingPlayers = Object.values(state.players).filter(p => p.place === undefined);
            looser.place = remainingPlayers.length;

            if (remainingPlayers.length === 2) {
                // The game is over. Assign the first place to the last remaining player.
                winner.place = 1;
            }
        }
    }
    if (phase.remaining <= 0) {
        state.currentPhase = { type: "attack", numberOfAttacks, hasReceivedCard };
    }
}
