import { NodeTag } from "./GameState";
import { makeWeakMapCached } from "./makeWeakMapCached";

export type EdgeList = ReadonlyArray<readonly [NodeTag, NodeTag]>;
type AdjacencyMap = ReadonlyMap<NodeTag, ReadonlySet<NodeTag>>;

function createAdjacencyMap(edges: EdgeList): AdjacencyMap {
    const adjacencyMap = new Map<NodeTag, Set<NodeTag>>();
    for (const [a, b] of edges) {
        const aList = adjacencyMap.get(a);
        if (aList !== undefined)
            aList.add(b);
        else
            adjacencyMap.set(a, new Set([b]));

        const bList = adjacencyMap.get(b);
        if (bList !== undefined)
            bList.add(a);
        else
            adjacencyMap.set(b, new Set([a]));
    }

    return adjacencyMap;
}
const getAdjacencyMap = makeWeakMapCached(createAdjacencyMap);
export function getNeighbours(edges: EdgeList, node: NodeTag): ReadonlySet<NodeTag> {
    return getAdjacencyMap(edges).get(node) ?? new Set();
}

export function isAdjacent(edges: EdgeList, nodeA: NodeTag, nodeB: NodeTag) {
    return getNeighbours(edges, nodeA).has(nodeB);
}
