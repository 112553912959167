import { PlayerCards } from './GameState';

export function tradeCardsForTroops(cards: PlayerCards): { gainedTroops: number; newCards: PlayerCards; } | null {
    if (cards[1] >= 1 && cards[2] >= 1 && cards[3] >= 1) {
        return {
            gainedTroops: 10,
            newCards: {
                1: cards[1] - 1,
                2: cards[2] - 1,
                3: cards[3] - 1,
            }
        };
    }
    else if (cards[3] >= 3) {
        return {
            gainedTroops: 8,
            newCards: {
                ...cards,
                3: cards[3] - 3,
            }
        };
    }
    else if (cards[2] >= 3) {
        return {
            gainedTroops: 6,
            newCards: {
                ...cards,
                2: cards[2] - 3,
            }
        };
    }
    else if (cards[1] >= 3) {
        return {
            gainedTroops: 4,
            newCards: {
                ...cards,
                1: cards[1] - 3,
            }
        };
    }
    else {
        return null;
    }
}
