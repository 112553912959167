import { AppState } from './AppState';
import { mapSelectionSlice } from '../game/mapSelectionSlice';
import { SharedState } from '../net/SharedState';
import { historySlice } from '../logic/historySlice';

export default function applyFullStateTransition(state: AppState, newState: SharedState): AppState {
    if (state.mode === "connecting" && newState.mode === "lobby") {
        return { ...state, ...newState }
    }
    if (state.mode === "connecting" && newState.mode === "multiplayer") {
        return {
            ...state, ...newState,
            selection: mapSelectionSlice.reducer(undefined, { type: "@INIT_MULTIPLAYER" }),
            history: historySlice.reducer(undefined, { type: "@INIT_MULTIPLAYER" })
        }
    }
    else if (state.mode === "lobby" && newState.mode === "lobby") {
        return { ...state, ...newState };
    }
    else if (state.mode === "multiplayer" && newState.mode === "multiplayer") {
        return { ...state, ...newState };
    }
    else if (state.mode === "lobby" && newState.mode === "multiplayer") {
        return {
            mode: "multiplayer",
            connection: state.connection,
            game: newState.game,
            playerTag: state.playerTag,
            selection: mapSelectionSlice.reducer(undefined, { type: "@INIT_MULTIPLAYER" }),
            history: historySlice.reducer(undefined, { type: "@INIT_MULTIPLAYER" })
        };
    }
    else if (state.mode === "multiplayer" && newState.mode === "lobby") {
        return {
            mode: "lobby",
            connection: state.connection,
            lobby: newState.lobby,
            playerTag: state.playerTag
        };
    }
    else {
        console.error(`Unknown transition from ${state.mode} to ${newState.mode} - skipping`);
        return state;
    }
}
