import React from 'react';
import './App.css';
import "./ui-utils/FancyButton.css";
import { Editor } from './editor/Editor';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Menu } from './Menu';
import { MultiplayerGame } from './game/MultiplayerGame';
import { SingleplayerGame } from './SingleplayerGame';
import { CreateGame } from './CreateGame';
import { enablePatches } from 'immer';
import { Credits } from './credits/Credits';
import FancyButtonPreload from './ui-utils/FancyButtonPreload';
import Imprint from './imprint/Imprint';

enablePatches();

export default function App() {
  return <>
    <FancyButtonPreload />
    <Router>
      <Switch>
        <Route path="/game/new/">
          <CreateGame />
        </Route>
        {process.env.NODE_ENV === "development" &&
          <Route path="/game/:gameId/:playerId">
            <MultiplayerGame />
          </Route>
        }
        <Route path="/game/:gameId/">
          <MultiplayerGame />
        </Route>
        <Route path="/game">
          <SingleplayerGame />
        </Route>
        <Route path="/editor">
          <Editor />
        </Route>
        <Route path="/credits">
          <Credits />
        </Route>
        <Route path="/imprint">
          <Imprint />
        </Route>
        <Route path="/">
          <Menu />
        </Route>
      </Switch>
      <Link to="/imprint" className="sneaky-imprint-link">Imprint</Link>
    </Router>
  </>
}
