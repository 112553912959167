import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ConnectionState = ActiveConnectionState | "disconnected";
interface ActiveConnectionState {
    ping: number;
}
export const connectionSlice = createSlice({
    name: "connection",
    initialState: "disconnected" as ConnectionState,
    reducers: {
        receivePing: (state, action: PayloadAction<number>) => {
            return { ping: action.payload };
        },
        setDisconnected(state) {
            return "disconnected";
        }
    },
});

export const { receivePing, setDisconnected } = connectionSlice.actions;
