import React from 'react';
import { useHistory } from 'react-router-dom';
import { apiBaseUrl } from './net/urls';

export function CreateGame() {
    const history = useHistory();
    React.useEffect(() => {
        const doStuff = async () => {
            const response = await fetch(apiBaseUrl() + "/startGame", { method: "POST" });
            const { gameId } = await response.json();
            history.replace(`/game/${gameId}`);
        };
        doStuff();
    }, [history]);

    return <div>Creating game...</div>;
}
