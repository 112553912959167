import React from 'react';
import { TroopCountInput } from './TroopCountInput';
import { useAppDispatch } from '../store/Store';
import { deselect, SecondSelected } from './mapSelectionSlice';
import { endPhase, moveTroops, startAttack } from '../logic/gameSlice';
import { selectEdgeCenter } from './selectEdgeCenter';
import { OngoingAttackPhase } from '../logic/GameState';
import { useGameSelector, useIsHistorical } from "../store/selectorHooks";

export function MapUi() {
  const isHistorical = useIsHistorical();
  const selectionState = useGameSelector(state => state.selection.type);
  const currentPhase = useGameSelector(state => state.game.currentPhase.type);
  if (isHistorical)
    return <></>
  if (selectionState === "bothSelected") {
    if (currentPhase === "move")
      return <TroopMoveUi />;
    else if (currentPhase === "attack")
      return <TroopAttackUi />;
  }
  if (currentPhase === "ongoingAttack")
    return <OngoingAttackUi />;
  return <></>;
}
function TroopMoveUi() {
  const { first, second } = useGameSelector(state => state.selection as SecondSelected);
  const firstNodeTroops = useGameSelector(state => state.game.nodes[first].troops);
  const dispatch = useAppDispatch();
  return <TroopCountInput
    a={first}
    b={second}
    max={firstNodeTroops - 1}
    onSubmit={count => {
      dispatch(moveTroops({ count, from: first, to: second }));
      dispatch(deselect());
    }} />;
}
function TroopAttackUi() {
  const { first, second } = useGameSelector(state => state.selection as SecondSelected);
  const firstNodeTroops = useGameSelector(state => state.game.nodes[first].troops);
  const dispatch = useAppDispatch();
  return <TroopCountInput
    a={first}
    b={second}
    max={firstNodeTroops - 1}
    onSubmit={count => {
      dispatch(startAttack({ count, from: first, to: second }));
      dispatch(deselect());
    }} />;
}
function OngoingAttackUi() {
  const { from, to, remaining } = useGameSelector(state =>
    (state.game.currentPhase as OngoingAttackPhase));
  const [x, y] = useGameSelector(selectEdgeCenter(from, to));
  const dispatch = useAppDispatch();

  return <div className="map-positioned-overlay remaining-troops" style={{
    top: `${y}%`,
    left: `${x}%`,
  }}
    onClick={() => dispatch(endPhase())}
  >
    {remaining}
  </div>
}
