import { createAction } from '@reduxjs/toolkit';
import { GameInfo } from './AppState';
import { SharedState } from '../net/SharedState';
import { GameState } from '../logic/GameState';

export const serverInit = createAction<{
    initialState: SharedState;
    initTime: number;
    history: GameState[];
}>("serverInit");
export const receiveState = createAction<SharedState>("receiveState");
export const initSingleplayerGame = createAction("initSinglePlayerGame");
export const initEditor = createAction("initEditor");
export const initChooseName = createAction<GameInfo>("initChooseName");
export const joinGame = createAction<{ name: string; }>("joinGame");
