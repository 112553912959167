import { useAppSelector } from '../store/Store';

/** A selector that determines whether it's the current player's turn. */
export default function useIsMyTurn() {
    return useAppSelector(state => {
        if (state.mode !== "multiplayer" && state.mode !== "singleplayer")
            throw new Error();

        if (state.history.selected !== "current")
            return false;

        if (state.mode === "multiplayer")
            return state.playerTag === state.game.currentPlayer;

        if (state.mode === "singleplayer")
            return true;
    });
}
