import React from 'react';
import { Game } from './game/Game';
import { useAppDispatch, useAppSelector } from './store/Store';
import { initSingleplayerGame } from "./store/specialActions";

export function SingleplayerGame() {
    const currentMode = useAppSelector(state => state.mode);
    const dispatch = useAppDispatch();
    if (currentMode !== "singleplayer") {
        dispatch(initSingleplayerGame());
        return <></>;
    }
    return <Game />;
}
