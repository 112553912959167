import ws from "ws";
import AutoResetEvent from "./AutoResetEvent";
import WebSocketCloseCode from "./WebSocketCloseCode";

export interface SocketClosedMessage {
    type: "socketClosed";
    code: number;
    reason: string;
}


export default class AsyncWebSocket<TReceived, TSent> {
    #socket: ws | WebSocket;
    #messageQueue: (TReceived | SocketClosedMessage)[] = []
    #newMessageEvent = new AutoResetEvent(undefined);
    lastMessageTime = performance.now();
    #isClosed = false;
    public get isClosed() { return this.#isClosed; }
    public constructor(socket: WebSocket | ws) {
        this.#socket = socket;
        this.#socket.onmessage = ({ data }: MessageEvent | ws.MessageEvent) => {
            if (typeof data !== "string") {
                this.#socket.close(WebSocketCloseCode.UNSUPPORTED_DATA);
                return;
            }
            const json = JSON.parse(data);
            this.#messageQueue.push(json);
            this.lastMessageTime = performance.now();
            this.#newMessageEvent.fire();
        };
        this.#socket.onclose = ({ code, reason }: CloseEvent | ws.CloseEvent) => this.#onClose(code, reason);
    }
    #onClose(code: number, reason: string) {
        if (this.#isClosed) return;

        this.#messageQueue.push({ type: "socketClosed", code, reason });
        this.#isClosed = true;
    }
    static async createClientSocket<TReceived, TSent>(url: string): Promise<AsyncWebSocket<TReceived, TSent> | "NoConnection"> {
        const websocket = new WebSocket(url);
        const socket = new AsyncWebSocket<TReceived, TSent>(websocket);
        return new Promise(resolve => {
            websocket.onopen = () => resolve(socket)
            websocket.onerror = () => {
                resolve("NoConnection");
            }
        });
    }
    async waitForMessage(): Promise<void> {
        if (this.#messageQueue.length > 0)
            return Promise.resolve();
        else if (this.#isClosed)
            throw new Error("Connection has been closed");
        return this.#newMessageEvent.wait();
    }
    popMessage(): TReceived | SocketClosedMessage {
        const result = this.#messageQueue.shift();
        if (result === undefined)
            throw new Error();
        return result;
    }
    async popNextMessage(): Promise<TReceived | SocketClosedMessage> {
        await this.waitForMessage();
        return this.popMessage();
    }
    send(message: TSent) {
        this.#socket.send(JSON.stringify(message));
    }
    close(code: WebSocketCloseCode, message: string) {
        this.#socket.close(code, message);
    }
    terminate() {
        if (!(this.#socket instanceof ws)) {
            console.error("Cannot terminate a websocket connection in the browser.");
            return;
        }
        if (this.#isClosed) {
            console.error("The socket is already closed.");
            return;
        }
        console.log("terminating");
        this.#socket.terminate();
        this.#onClose(-1, "Socket terminated.");
    }
}
