import React from "react";
import { getMetadata, PortraitMetadata } from "./portraits";
import { Tooltip } from "./ui-utils/Tooltip";

export function PortraitWithTooltip(props: { src: string; } & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
    const [metadata, setMetadata] = React.useState<PortraitMetadata | null>(null);
    React.useEffect(() => {
        // If the component was unmounted and we held a reference to setMetadata
        // through this callback, we get a memory leak - the unmounted component
        // cannot be collected.
        // This workaround does not completely fix the problem, but at least the
        // amount of un-reclaimable memory is limited to this callback.
        let _setMetadata: typeof setMetadata | null = setMetadata;
        async function fetch() {
            const metadata = await getMetadata(props.src);
            if (_setMetadata)
                _setMetadata(metadata);
        }
        fetch();
        return () => { _setMetadata = null; };
    }, [props.src, setMetadata]);
    return <Tooltip>
        <div>
            <strong>{metadata?.name}</strong>
            <div>
                {metadata?.biography}
            </div>
        </div>
        <img alt={metadata?.name} {...props} />
    </Tooltip>;
}
