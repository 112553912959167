import React from 'react';
import { selectEdgeCenter } from './selectEdgeCenter';
import { useGameSelector } from "../store/selectorHooks";
import { deselect } from './mapSelectionSlice';
import { useAppDispatch } from '../store/Store';
import "./TroopCountInput.css"

export function TroopCountInput(props: { a: string; b: string; max: number; onSubmit: (count: number) => void }) {
    const { a, b, max, onSubmit } = props;
    const [x, y] = useGameSelector(selectEdgeCenter(a, b));
    const [amount, setAmount] = React.useState(max);
    const dispatch = useAppDispatch();

    if (amount > max) setAmount(max);
    if (amount < 0) setAmount(0);
    return <div className="map-positioned-overlay troop-count" style={{
        top: `${y}%`,
        left: `${x}%`,
    }}
        onWheel={e => {
            setAmount(x => x - Math.sign(e.deltaY));
            e.preventDefault();
        }}
        onContextMenu={e => e.preventDefault()}
    >
        <span className="troop-count-amount"
            onMouseDown={e => setAmount(x => {
                if (e.button !== 0 && e.button !== 2) return x;
                e.preventDefault();
                let delta = 1;
                if (e.button === 2) {
                    delta = -1;
                }

                if (e.ctrlKey) {
                    if (delta < 0)
                        return 1;
                    else
                        return max;
                }

                if (e.shiftKey)
                    delta *= 5;
                return Math.max(Math.min(x + delta, max), 0);
            })}>{amount}</span>
        <div className="troop-count-button troop-count-submit" onClick={() => onSubmit(amount)}>✓&#xFE0E;</div>
        <div className="troop-count-button troop-count-abort" onClick={() =>
            dispatch(deselect())}>❌&#xFE0E;</div>

    </div>;
}
