import classNames from "classnames";
import React from "react";
import portraitUrls from "../portraits";
import { useLobbySelector } from "../store/selectorHooks";
import { useAppDispatch } from "../store/Store";
import Dropdown from "./Dropdown";
import { colorCombinations, setColors, setPortrait } from "./lobbySlice";
import { PortraitWithTooltip } from "../PortraitWithTooltip";
import arrayEquals from "../arrayEquals";
import { PlayerTag } from "../logic/GameState";

export default function PlayerList() {
    const players = useLobbySelector(state => Object.values(state.lobby.connectedPlayers), arrayEquals);
    const self = useLobbySelector(state => state.playerTag);

    return <div className="lobby-player-list">
        {players.map(({ tag, isReady, portrait }) =>
            <div className="player-box" key={tag}>
                <PlayerName tag={tag} />
                <div className="player-box-avatar">
                    {/* <PortraitWithTooltip className="lobby-player-portrait" src={portrait} alt="" /> */}
                    {tag === self ? <PortraitSelection /> : <PortraitWithTooltip className="lobby-player-portrait" src={portrait} alt="" />}
                </div>
                <div className="player-box-data lobby-player-ready">{isReady ? "✓" : "⨯"}</div>
            </div>)}
    </div>;
}

function PortraitSelection() {
    const player = useLobbySelector(state => state.playerTag)
    const portrait = useLobbySelector(state => state.lobby.connectedPlayers[state.playerTag].portrait);
    const dispatch = useAppDispatch();

    return <div className="lobby-player-portrait" >
        <PortraitWithTooltip src={portrait} className="lobby-player-portrait" />
        <Dropdown>
            <></>
            <div className="lobby-player-portrait-dropdown">
                {portraitUrls.map(portrait => <PortraitWithTooltip src={portrait} key={portrait} onClick={() => dispatch(setPortrait({ tag: player, portrait }))} />
                )}
            </div>
        </Dropdown>
    </div>
}

function PlayerName(props: { tag: PlayerTag }) {
    const { tag } = props;
    const isHost = useLobbySelector(state => state.lobby.host === tag);
    const isSelf = useLobbySelector(state => state.playerTag === tag);
    const name = useLobbySelector(state => state.lobby.connectedPlayers[tag].name);
    const colors = useLobbySelector(state => colorCombinations[state.lobby.connectedPlayers[tag].colorChoice]);

    const dispatch = useAppDispatch();
    const nameElement = <div
        className={classNames("lobby-player-name",
            {
                "lobby-player-host": isHost,
                "lobby-player-self": isSelf,
            })}
    >
        {name}
    </div>;
    return <div className="player-box-name"
        style={{
            background: colors.color,
            color: colors.textColor
        }}>
        {nameElement}
        {isSelf && <Dropdown>
            <></>
            {colorCombinations.map(({ color, textColor }, i) =>
                <div key={i} style={{ backgroundColor: color, color: textColor }} onClick={() => dispatch(setColors({ tag, colorChoice: i }))}>
                    &emsp;
                </div>)}
        </Dropdown>}
    </div>
}


