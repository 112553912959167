import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "@reduxjs/toolkit/node_modules/immer/dist/internal";
import { serverInit } from "../store/specialActions";
import { GameState } from "./GameState";

export interface HistoryState {
    previousGameStates: GameState[]
    selected: "current" | number
}

export const historySlice = createSlice({
    name: "history",
    initialState: {
        previousGameStates: [],
        selected: "current",
    } as HistoryState,
    reducers: {
        pushState: (historyState, action: PayloadAction<GameState>) => {
            historyState.previousGameStates.push(action.payload as WritableDraft<GameState>)
        },
        goTo: (state, action: PayloadAction<"current" | number>) => {
            if (typeof action.payload === "number"
                && state.previousGameStates[action.payload] === undefined) {
                console.error(`Invalid command: Game state with index ${action.payload} does not exist.`);
                return;
            }
            state.selected = action.payload;
        }
    },
    extraReducers: builder => builder
        .addCase(serverInit, (state, action) => {
            state.previousGameStates = action.payload.history as WritableDraft<GameState[]>;
        })
})

export const getHistoryAction = (oldState: GameState, newState: GameState):
    ReturnType<typeof pushState> | undefined => {
    // Save the state at the end of each turn (excluding initial placements).
    if (oldState.currentPlayer !== newState.currentPlayer
        && oldState.currentPhase.type !== "initialPlacement") {
        return pushState(oldState);
    }
    // Save the state at the end of the initial placement phase.
    else if (oldState.currentPhase.type === "initialPlacement"
        && newState.currentPhase.type !== "initialPlacement") {
        return pushState(newState);
    }
}

export const { pushState, goTo } = historySlice.actions;