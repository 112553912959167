import { GameState } from '../logic/GameState';
import { ConnectionState } from '../net/connectionSlice';
import { useAppSelector } from './Store';
import { GameModeBase, AppState, EditorMode, LobbyMode, ChooseNameMode } from './AppState';

// only used in JSDoc links
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useSelector } from "react-redux";

type UseSelectorHook<TState> = <T>(selector: (state: TState) => T, equalityFn?: (a: T, b: T) => boolean) => T;

/**
 * Applies a selector to the active game state, which might be historical. 
 * Similar to {@link useSelector}.
 * 
 * Not to be confused with {@link useGameSelector} , which always refers to the
 * current game state and can also be used to access e.g. the history itself.
 */
export const useGameState: UseSelectorHook<GameState> = (selector, equalityFn) => {
    return useAppSelector(state => {
        let _state: GameModeBase;
        if (process.env.NODE_ENV !== "production") {
            if (state.mode !== "multiplayer" && state.mode !== "singleplayer")
                throw new Error("Can't access game state while not in game mode");
            _state = state;
        }

        else
            _state = state as GameModeBase;

        let gameState: GameState;
        if (_state.history.selected === "current")
            gameState = _state.game;

        else
            gameState = _state.history.previousGameStates[_state.history.selected];

        return selector(gameState);
    }, equalityFn);
};

export const useIsHistorical = () => useGameSelector(state => state.history.selected !== "current");

/** Like {@link useSelector}, but assumes that we are in singleplayer or multiplayer mode. */
export const useGameSelector: UseSelectorHook<GameModeBase> = (selector, equalityFn) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    if (process.env.NODE_ENV === "production")
        return useAppSelector(selector as any, equalityFn);

    else
        return useAppSelector(state => {
            if (state.mode !== "multiplayer" && state.mode !== "singleplayer")
                throw new Error("Can't access game state while not in game mode");
            return selector(state);
        }, equalityFn);
    /* eslint-enable react-hooks/rules-of-hooks */
};
function makeMetaSelector<TState extends AppState>(id: TState["mode"]): UseSelectorHook<TState> {
    const useSelector: UseSelectorHook<TState> = (selector, equalityFn) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        if (process.env.NODE_ENV === "production")
            return useAppSelector(selector as any, equalityFn);

        else
            return useAppSelector(state => {
                if (state.mode !== id)
                    throw new Error(`Can't access ${id} state while not in ${id} mode`);
                return selector(state as TState);
            }, equalityFn);
        /* eslint-enable react-hooks/rules-of-hooks */
    };
    return useSelector;
}

/** Like {@link useSelector}, but assumes that we are in editor mode. */
export const useEditorSelector = makeMetaSelector<EditorMode>("editor");
/** Like {@link useSelector}, but assumes that we are in lobby mode. */
export const useLobbySelector = makeMetaSelector<LobbyMode>("lobby");
/** Like {@link useSelector}, but assumes that we are in chooseName mode. */
export const useChooseNameSelector = makeMetaSelector<ChooseNameMode>("chooseName");
export const useConnectionSelector: UseSelectorHook<ConnectionState> = (selector, equalityFn) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    if (process.env.NODE_ENV === "production")
        return useAppSelector(x => selector((x as any).connection), equalityFn);

    else
        return useAppSelector(state => {
            if (state.mode !== "multiplayer"
                && state.mode !== "lobby"
                && state.mode !== "chooseName")
                throw new Error("Can't access connection state while not in connection mode");
            return selector(state.connection);
        }, equalityFn);
    /* eslint-enable react-hooks/rules-of-hooks */
};
