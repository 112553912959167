import React from 'react';
import { ContinentTag, MapNode, NodeTag } from '../logic/GameState';
import classNames from 'classnames';
import { addContinent, addEdge, addNode, loadWorldMap, removeEdge, removeNode } from './mapSlice';
import { displayedModes, selectContinent, setLastNode, setMode } from './editModeSlice';
import { useAppDispatch, useAppSelector } from '../store/Store';
import { initEditor } from "../store/specialActions";
import { useEditorSelector } from "../store/selectorHooks";
import './Editor.css';
import { toInteger } from 'lodash';
import getTag from './getTag';

export function Editor() {
  const isInEditorMode = useAppSelector(state => state.mode === "editor");
  const dispatch = useAppDispatch();
  if (isInEditorMode)
    return <ActualEditor />
  else {
    dispatch(initEditor());
    return <></>
  }
}
function ActualEditor() {
  const [mousePos, setMousePos] = React.useState<[number, number]>([0, 0]);

  const dispatch = useAppDispatch();
  const edges = useEditorSelector(state => state.map.edges);
  const nodes = useEditorSelector(state => Object.values(state.map.nodes));
  const currentMode = useEditorSelector(state => state.editMode.mode.type);
  const continent = useEditorSelector(state => state.editMode.selectedContinent);
  const { image, width, height } = useEditorSelector(state => state.map.baseMap);

  const onCanvasClick = (e: React.MouseEvent) => {
    if (e.button !== 0) return;
    if (currentMode === "addNode") {
      const { x, y, width, height } = e.currentTarget.getClientRects()[0];
      //TODO: show overlay
      if (continent !== null) {
        dispatch(setMode({
          type: "chooseNodeName",
          position: [
            (e.clientX - x) / width * 100,
            (e.clientY - y) / height * 100
          ],
          continent
        }));

        // do not do any selection, allowing the appearing input field to get auto-focused
        e.preventDefault();
      }
      else alert("Please select a continent before creating a node.");
      e.stopPropagation();
    }
  }

  const onMouseMove = (e: React.MouseEvent) => {
    const { x, y } = e.currentTarget.getClientRects()[0];
    setMousePos([
      e.clientX - x,
      e.clientY - y
    ])
  }

  return (
    <div className="App">
      <div>
        <ModeList />
        <button onClick={() => dispatch(loadWorldMap())}>
          Load world map
        </ button>
      </div>
      <svg className="map-canvas" onMouseDown={onCanvasClick} onMouseMove={onMouseMove}
        style={{ aspectRatio: `${width} / ${height}` }}>
        <image href={image} height="100%" />
        {edges.map(([a, b]) => <EdgeComponent key={`${a}-${b}`} edge={[a, b]} />)}
        <EdgePreview key="edgePreview" targetPos={mousePos} />
        {nodes.map(node => <NodeComponent key={node.tag} node={node} />)}
      </svg>
      <ContinentList />
      {currentMode === "chooseNodeName" && <ChooseNameModal />}
    </div>
  );
}

function ModeList() {
  const dispatch = useAppDispatch();
  const currentMode = useEditorSelector(state => state.editMode.mode.type);

  return <div className="editor-modes">
    Editor mode:
    {displayedModes.map(mode => <button
      key={mode.type}
      onClick={() => {
        dispatch(setMode({ type: mode.type }));
        dispatch(setLastNode(null));
      }}
      className={classNames("editor-mode", { "editor-mode-selected": currentMode === mode.type })}>
      {mode.text}
    </button>)}
  </div>
}

function NodeComponent(props: { node: MapNode }) {
  const { position, tag, continent } = props.node;
  const lastNode = useEditorSelector(state => state.editMode.lastNode);
  const currentMode = useEditorSelector(state => state.editMode.mode.type);
  const isSelectedContinent = useEditorSelector(state => continent === state.editMode.selectedContinent);
  const dispatch = useAppDispatch();
  const [x, y] = position.map(x => `${x}%`);

  return <>
    <circle key={tag}
      cx={x} cy={y} r="8" fill={isSelectedContinent ? "blue" : "gray"} stroke="red"
      onClick={() => {
        if (currentMode === "removeNode") {
          dispatch(removeNode(tag));
        }
        else if (currentMode === "addEdge") {
          if (lastNode === null) {
            dispatch(setLastNode(tag))
          } else {
            dispatch(addEdge([lastNode, tag]));
            dispatch(setLastNode(null));
          }
        }
      }}
    />
    <text {...{ x, y }} className="shadow" dominantBaseline="hanging" textAnchor="middle">
      {props.node.name}
    </text>
  </>;
}

function EdgeComponent(props: { edge: [NodeTag, NodeTag] }) {
  const [a, b] = props.edge;
  const pos1 = useEditorSelector(state => state.map.nodes[a].position)
  const pos2 = useEditorSelector(state => state.map.nodes[b].position);
  const [x1, y1, x2, y2] = [...pos1, ...pos2].map(x => `${x}%`);
  const currentMode = useEditorSelector(state => state.editMode.mode.type);
  const dispatch = useAppDispatch();
  let stroke = "black";
  let strokeWidth = 1.5;
  return <>
    <line className="shadow" {...{ x1, y1, x2, y2, stroke, strokeWidth }} />
    <line {...{ x1, y1, x2, y2 }} stroke="transparent" strokeWidth="20"
      onClick={() => {
        console.log(a, b)
        if (currentMode === "removeEdge")
          dispatch(removeEdge([a, b]));
      }} />
  </>;
}

function EdgePreview(props: { targetPos: [number, number] }) {
  const nodePosition = useEditorSelector(state =>
    state.editMode.lastNode !== null ?
      state.map.nodes[state.editMode.lastNode].position :
      null)
  if (nodePosition === null)
    return <></>;
  const [x1, y1] = nodePosition.map(x => `${x}%`);
  const [x2, y2] = props.targetPos;
  return <line {...{ x1, y1, x2, y2 }} stroke="black"
    strokeWidth="1.5"
    strokeDasharray="2, 2"
  />
}

function ContinentList() {
  const [addNew, setAddNew] = React.useState(false);
  const continents = useEditorSelector(state => Object.values(state.map.continents));
  const dispatch = useAppDispatch();
  const selectedContinent = useEditorSelector(state => state.editMode.selectedContinent);

  return <table className="editor-continent-list">
    <colgroup>
      <col span={1} />
      <col span={1} />
    </colgroup>
    <thead>
      <tr>
        <th>Continent</th>
        <th>Bonus Troops</th>
      </tr>
    </thead>
    <tbody>
      {continents.map(({ tag, name, bonusTroops }) =>
        <tr
          key={tag}
          onClick={() => dispatch(selectContinent(tag))}
          className={classNames({ "editor-continent-selected": tag === selectedContinent })}
        >
          <td>{name}</td><td>{bonusTroops}</td>
        </tr>
      )}
      {
        !addNew ?
          <tr><td>
            <button onClick={() => setAddNew(true)}>Add new</button>
          </td></tr>
          :
          <ContinentEditor hide={() => setAddNew(false)} />
      }
    </tbody>
  </table>
}

function ContinentEditor({ hide }: { hide: () => void }) {
  const [name, setName] = React.useState("");
  const [bonusTroops, setBonusTroops] = React.useState(0);
  const dispatch = useAppDispatch();

  const submit = () => {
    hide();
    dispatch(addContinent({
      tag: name.toLowerCase() as ContinentTag,
      name,
      bonusTroops,
    }));
  };

  return <tr>
    <td>
      <input type="text" value={name} onChange={e => setName(e.target.value)} onKeyPress={e => e.key === "Enter" && submit()} />
    </td>
    <td>
      <input type="number" value={bonusTroops} onChange={e => setBonusTroops(toInteger(e.target.value))} min={0} />
      <button onClick={submit}>Add</button>
    </td>
  </tr>;
}

function ChooseNameModal() {
  const { continent, position } = useEditorSelector(state => {
    if (state.editMode.mode.type !== "chooseNodeName")
      throw new Error();
    return state.editMode.mode;
  })
  //TODO: ensure that tag is unique
  const [tag, setTag] = React.useState(() => getTag());
  const [name, setName] = React.useState("");
  const dispatch = useAppDispatch();

  const focusedFieldRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    console.log(focusedFieldRef.current);
    focusedFieldRef.current?.focus();
  }, []);

  const cancel = (e: React.SyntheticEvent) => {
    dispatch(setMode({ type: "addNode" }))
    e.preventDefault()
  }

  return <form
    className="editor-choose-name-modal"
    onSubmit={() => dispatch(addNode({ tag, name, continent, position }))}
    onKeyDown={e => e.key === "Escape" && cancel(e)}
  >

    Tag: <input type="text"
      value={tag}
      onChange={e => setTag(e.currentTarget.value as NodeTag)}
    />
    Name: <input type="text" autoFocus
      value={name}
      onChange={e => setName(e.currentTarget.value)}
    />
    <div
      className="editor-choose-name-modal-buttons">
      <button
        type="submit"
        className="fancy-button"
      >
        OK
      </button>
      <button
        className="fancy-button"
        onClick={cancel}
      >
        Cancel
      </button>
    </div>
  </form>
}
