export interface ResourceMetadata {
    /** The URL to the resource. */
    url: string,
    /** The human-readable name of this work. */
    name: string,
    /** An optional attribution string. */
    credits?: string,
    /** 
     * The license of this work. 
     * This must be compatible, but not necessarily equal to the source 
     * license(s). 
     **/
    license: License,
    /** The sources of this work, including their licenses. */
    sources: Source[]
}

export type Source = "OwnWork" | ExternalSource;

export interface ExternalSource {
    /** 
     * The URL to the original source. 
     * This should not need to a URL containing the resource itself, but rather
     * a description page of the resource (e.g. Wikimedia Commons page), which 
     * displays the license, author and other metadata.
     **/
    url: string,
    /** The license of the work, as stated on its description page. */
    license: License,
    /** An optional comment about this source. */
    comment?: string,
}

export type License = {
    name: string;
    link: string;
} | "PublicDomain";

const cachedMetadata = new Map<string, ResourceMetadata>();

export async function getMetadata(url: string) {
    const cached = cachedMetadata.get(url);
    if (cached !== undefined) return cached;
    const req = await fetch(url + ".meta.json");
    const meta = await req.json();
    meta.url = url;
    const result = meta as ResourceMetadata;
    cachedMetadata.set(url, result);
    return result;
}
