import classNames from 'classnames';
import React from 'react';
import { endPhase, placeInitialArmy, setPlacedTroops } from '../logic/gameSlice';
import { GameNode } from '../logic/GameState';
import { getRemainingTroops } from '../logic/getRemainingTroops';
import { selectNode } from './mapSelectionSlice';
import { useAppDispatch } from '../store/Store';
import { useGameSelector, useGameState, useIsHistorical } from "../store/selectorHooks";
import "./Nodes.css"
import { SvgTooltip } from '../ui-utils/Tooltip';
import { ActualSvgSizeContext } from './Game';

export function Nodes() {
    const nodes = useGameState(state => state.nodes);

    return <>
        {Object.values(nodes)
            .map((node) => {
                return <NodeComponent key={node.tag} node={node} />;
            })}
    </>;
}

function PopoutBackground() {
    return <>
        <g className="map-node-popout-rect" >
            <path
                d="M 0 -10 H 1 L 1 10 H 0"
                stroke="none"
            />
            <path
                d="M 0 -10 H 1 M 1 10 H 0"
                fill="none"
            />
        </g>
        <path
            className="map-node-popout-arc"
            d="M 0 -10 a 1 1 0 0 1 0 20"
        />
    </>
}

function Popout(props: { x: number, y: number, visible: boolean, children?: React.ReactNode }) {
    const { x, y, visible, children } = props;
    const [width, height] = React.useContext(ActualSvgSizeContext);
    const _x = x / 100 * width;
    const _y = y / 100 * height;
    return <g
        className={classNames("map-node-popout", { "map-node-popout-visible": visible })}>
        <g style={{ transform: `translate(${_x}px, ${_y}px)` }} x={x} y={y} href="#mapNodePopout">
            <PopoutBackground />
        </g>
        <text x={_x} y={_y} textAnchor="end" dominantBaseline="central">{children}</text>
    </g>
}

function NodeComponent(props: {
    node: GameNode;
}) {
    const { node: { position: [_x, _y], tag, troops, owner: ownerTag } } = props;
    const isHistorical = useIsHistorical();
    const selection = useGameSelector(state => state.selection);
    const currentPhase = useGameState(state => state.currentPhase);
    const owner = useGameState(state => state.players[ownerTag]);
    const dispatch = useAppDispatch();
    const placed = useGameState(state => state.currentPhase.type === "place"
        && state.currentPhase.troopDistribution[tag]) || undefined;
    const remaining = currentPhase.type === "place" ? getRemainingTroops(currentPhase) : 0;
    const maxPlaceable = (placed ?? 0) + remaining;

    const placeTroops = (delta: number) => {
        let count = (placed ?? 0) + delta;
        count = Math.min(count, maxPlaceable);
        count = Math.max(count, 0);
        dispatch(setPlacedTroops({ target: tag, count }));
    }

    const [x, y] = [`${_x}%`, `${_y}%`];

    let stroke = "black";
    if (!isHistorical && selection.type !== "noneSelected" && selection.first === tag) {
        stroke = "red";
    }
    return <>
        <Popout x={_x} y={_y} visible={placed !== undefined}>
            +{placed}
        </Popout>
        <SvgTooltip>
            <NodeTooltip node={props.node} />
            <circle
                className={classNames("map-node", { "map-node-placed": placed !== undefined })}
                key={tag} cx={x} cy={y} r="10"
                fill={owner.color} stroke={stroke}
                onMouseDown={e => {
                    if (isHistorical) return;
                    if (currentPhase.type === "place") {
                        if (e.ctrlKey) {
                            if (e.button === 0)
                                dispatch(setPlacedTroops({ target: tag, count: maxPlaceable }));
                            else if (e.button === 2)
                                dispatch(setPlacedTroops({ target: tag, count: 0 }));
                        }
                        else {
                            let delta = e.shiftKey ? 5 : 1;
                            if (e.button === 2)
                                delta *= -1;
                            placeTroops(delta);
                        }
                    }
                    else if (currentPhase.type === "initialPlacement") {
                        dispatch(placeInitialArmy(tag));
                    }
                    else if (currentPhase.type === "ongoingAttack")
                        dispatch(endPhase());
                    else
                        dispatch(selectNode(tag));
                    e.stopPropagation();
                }}
                onWheel={e => {
                    if (currentPhase.type === "place") {
                        placeTroops(-Math.sign(e.deltaY));
                        e.preventDefault();
                    }
                }}
                onContextMenu={e => e.preventDefault()}
            />
            <text
                className={classNames("map-troop-count", { "map-troops-count-placed": placed !== undefined })}
                x={x} y={y} textAnchor="middle" dominantBaseline="central"
                fill={owner.textColor}>
                {troops}
            </text>
        </SvgTooltip>
    </>;
}

function NodeTooltip(props: { node: GameNode }) {
    const { node: { name, continent: continentTag, owner } } = props;
    const continent = useGameState(state => state.continents[continentTag]);
    const playerName = useGameState(state => state.players[owner].name);

    return <>
        <b>{name}</b>
        <br />
        <ul>
            <li>
                Continent: {continent.name} (+{continent.bonusTroops})
            </li>
            <li>
                Owner: {playerName}
            </li>
        </ul>
    </>
}
