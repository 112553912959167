import React from 'react';
import { useGameSelector, useGameState, useIsHistorical } from "../store/selectorHooks";
import { getNeighbours } from '../logic/adjacency';
import { NodeTag, OngoingAttackPhase } from '../logic/GameState';
import "./Edges.css";
import { useAppDispatch } from '../store/Store';
import { endPhase } from '../logic/gameSlice';
import { AttackLogEntry, MoveLogEntry } from '../logic/TurnLog';
import { ActualSvgSizeContext } from './Game';
import arrayEquals from '../arrayEquals';

function ArrowHeadDef() {
  return <defs>
    <marker id="attackArrow" markerWidth="20" markerHeight="16" refX="28" refY="8" orient="auto" markerUnits="userSpaceOnUse">
      <path d="M0,3 L20,8 L0,13 z" />
    </marker>
  </defs>
}

export function Edges() {
  return <>
    <LogEdges />
    <UiEdges />
  </>
}

function UiEdges() {
  const isHistorical = useIsHistorical();
  const currentPhase = useGameSelector(state => state.game.currentPhase.type);

  if (isHistorical)
    return <></>
  if (currentPhase === "move" || currentPhase === "attack")
    return <SelectedEdges />
  if (currentPhase === "ongoingAttack")
    return <OngoingAttackEdge />
  return <></>
}

function LogEdges() {
  const movements = useGameState(state => state.turnLog.filter(x => x.type === "move"), arrayEquals) as MoveLogEntry[];
  const attacks = useGameState(state => state.turnLog.filter(x => x.type === "attack"), arrayEquals) as AttackLogEntry[];
  const currentPhase = useGameState(state => state.currentPhase.type);


  return <g className="log-edges">
    <marker id="logMoveArrow" markerWidth="20" markerHeight="16" refX="28" refY="8" orient="auto" markerUnits="userSpaceOnUse">
      <path d="M8,5 L20,8 L8,11 z" />
    </marker>
    <marker id="logAttackArrow" markerWidth="20" markerHeight="16" refX="28" refY="8" orient="auto" markerUnits="userSpaceOnUse">
      <path d="M8,5 L20,8 L8,11 z" />
    </marker>
    <g className="log-edges-movements">
      {movements.map(({ from, to, count }, i) => <EdgeWithText key={i} edge={[from, to]} >{count}</EdgeWithText>)}
    </g>
    <g className="log-edges-attacks">
      {attacks.map(({ from, to, attackingTroops }, i) => {
        if (i === attacks.length - 1 && currentPhase === "ongoingAttack")
          return undefined;
        return <EdgeWithText key={i} edge={[from, to]} >{attackingTroops}</EdgeWithText>
      })}
    </g>
  </g>
}

function EdgeWithText(props: { edge: [NodeTag, NodeTag], children: React.ReactChild }) {

  const { edge: [aTag, bTag] } = props;
  const [ax, ay] = useGameState(state => state.nodes[aTag].position);
  const [bx, by] = useGameState(state => state.nodes[bTag].position);

  // The SVG Arc does not accept % positions, so we'll have to convert them into
  // absolute values. To do so, we need to know the rendered size of the map.
  const [width, height] = React.useContext(ActualSvgSizeContext);
  const [x1, y1] = [ax * width / 100, ay * height / 100];
  const [x2, y2] = [bx * width / 100, by * height / 100];

  // Center of the edge
  const [cx, cy] = [(x1 + x2) / 2, (y1 + y2) / 2];
  // directional vector of the edge
  const [dx, dy] = [x2 - x1, y2 - y1];
  // length of the edge
  const d = Math.sqrt(dx ** 2 + dy ** 2);
  // radius of the arc - a little magic, this is simply what's looking good
  const r = d * 3;

  const arc = <path
    d={`M ${x1},${y1} A ${r},${r} 0 0 0 ${x2},${y2}`}
    className="edge-arrow" pathLength="1" />;
  // the pathLength="1" is just here to make the edge animatable

  // perpendicular vector to the edge, normalized
  const [px, py] = [-dy / d, dx / d];
  const textDist = Math.max(d * 0.043, 8);
  // position of the text - should be just a little off the side of the arc
  const [tx, ty] = [cx + px * textDist, cy + py * textDist];


  return <g>
    {arc}
    <text x={tx} y={ty} textAnchor="middle" dominantBaseline="central">{props.children}</text>
  </g>
}

function SelectedEdges() {
  const selection = useGameSelector(state => state.selection);
  if (selection.type === "noneSelected")
    return <></>
  if (selection.type === "firstSelected") {
    return <NeighbourEdges origin={selection.first} className="movement-edges-firstSelected" />
  }
  if (selection.type === "bothSelected") {
    const { first, second } = selection;
    return <SingleEdge className="movement-edges-bothSelected" edge={[first, second]} />
  }
  return <></>
}

function NeighbourEdges(props: { origin: NodeTag, className: string }) {
  const first = props.origin;
  const edges = useGameSelector(state => state.game.edges);
  const neighbours = getNeighbours(edges, first);
  const currentPlayer = useGameSelector(state => state.game.currentPlayer);
  const currentPhase = useGameSelector(state => state.game.currentPhase.type);
  const nodes = useGameSelector(state => state.game.nodes);

  return <g className={"movement-edges " + props.className}>
    <ArrowHeadDef />
    {[...neighbours]
      .filter(currentPhase === "attack" ?
        n => nodes[n].owner !== currentPlayer :
        n => nodes[n].owner === currentPlayer)
      .map(second => <EdgeComponent key={`${first}-${second}`} edge={[first, second]} />)}
  </g>

}

function SingleEdge(props: { edge: [NodeTag, NodeTag], className: string } & React.SVGProps<SVGGElement>
) {
  const { className, edge } = props;
  return <g {...props} className={"movement-edges " + className}>
    <ArrowHeadDef />
    <EdgeComponent edge={edge} />
  </g>
}

function OngoingAttackEdge() {
  const { from, to } = useGameSelector(state => state.game.currentPhase as OngoingAttackPhase)
  const dispatch = useAppDispatch();
  return <SingleEdge
    className="movement-edges-attacking"
    edge={[from, to]}
    onClick={() => dispatch(endPhase())}
  />
}

function EdgeComponent(props: { edge: [NodeTag, NodeTag] }) {
  const { edge: [aTag, bTag] } = props;
  const [ax, ay] = useGameSelector(state => state.game.nodes[aTag].position);
  const [bx, by] = useGameSelector(state => state.game.nodes[bTag].position);

  const [x1, y1, x2, y2] = [ax, ay, bx, by].map(x => `${x}%`);
  return <line {...{ x1, y1, x2, y2 }} className="edge-arrow" markerEnd="url(#attackArrow)" pathLength="1" />;
}
