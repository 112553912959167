import _ from "lodash";
import { DiceRoll, rollDice } from "./dice";

interface PlayerFightResult {
    rolls: DiceRoll[];
    losses: number;
}

export interface FightResult {
    attacker: PlayerFightResult;
    defender: PlayerFightResult;
}

export function rollFightDice(attackingTroops: number, defendingTroops: number): FightResult {
    const attackerDiceRolls = rollDice(attackingTroops)
        .sort((a, b) => b - a);
    const defenderDiceRolls = rollDice(defendingTroops)
        .sort((a, b) => b - a);

    let attackerLosses = 0;
    let defenderLosses = 0;

    for (const [attackerRoll, defenderRoll] of _.zip(attackerDiceRolls, defenderDiceRolls)) {

        if (attackerRoll === undefined || defenderRoll === undefined)
            break;

        if (attackerRoll > defenderRoll)
            defenderLosses++;

        else
            attackerLosses++;
    }

    return {
        attacker: {
            losses: attackerLosses,
            rolls: attackerDiceRolls
        },
        defender: {
            losses: defenderLosses,
            rolls: defenderDiceRolls
        }
    }
}
