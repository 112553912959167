import { ContinentTag, GameMap, NodeTag } from "./GameState";

const initialGameState: GameMap = {
    baseMap: {
        image: "/worldMap.svg",
        width: 749.81909,
        height: 519.06781
    },
    nodes: {
        "ala": {
            tag: "ala" as NodeTag,
            name: "Alaska",
            position: [7.852757659078249, 16.49659863945578],
            continent: "na" as ContinentTag
        },
        "nwt": {
            tag: "nwt" as NodeTag,
            name: "Northwest Territory",
            position: [17.035890872111374, 15.306122448979592],
            continent: "na" as ContinentTag
        },
        "alb": {
            tag: "alb" as NodeTag,
            name: "Alberta",
            position: [14.916706284488344, 22.61904761904762],
            continent: "na" as ContinentTag
        },
        "on": {
            tag: "on" as NodeTag,
            name: "Ontairo",
            position: [22.333852341168946, 24.149659863945576],
            continent: "na" as ContinentTag
        },
        "qe": {
            tag: "qe" as NodeTag,
            name: "Quebec",
            position: [28.33820867276752, 25.34013605442177],
            continent: "na" as ContinentTag
        },
        "gl": {
            tag: "gl" as NodeTag,
            name: "Greenland",
            position: [34.57802995854644, 11.904761904761903],
            continent: "na" as ContinentTag
        },
        "usw": {
            tag: "usw" as NodeTag,
            name: "Western US",
            position: [16.329496009570363, 34.523809523809526],
            continent: "na" as ContinentTag
        },
        "use": {
            tag: "use" as NodeTag,
            name: "Eastern US",
            position: [23.040247203709953, 36.904761904761905],
            continent: "na" as ContinentTag
        },
        "ca": {
            tag: "ca" as NodeTag,
            name: "Central America",
            position: [18.095483165922886, 47.10884353741497],
            continent: "na" as ContinentTag
        },
        "ve": {
            tag: "ve" as NodeTag,
            name: "Venezuela",
            position: [23.86437454334113, 53.91156462585034],
            continent: "sa" as ContinentTag
        },
        "br": {
            tag: "br" as NodeTag,
            name: "Brazil",
            position: [31.98791546256274, 63.94557823129252],
            continent: "sa" as ContinentTag
        },
        "p": {
            tag: "p" as NodeTag,
            name: "Peru",
            position: [21.509725001537767, 63.94557823129252],
            continent: "sa" as ContinentTag
        },
        "ar": {
            tag: "ar" as NodeTag,
            name: "Argentina",
            position: [25.394896745513318, 80.27210884353741],
            continent: "sa" as ContinentTag
        },
        "ic": {
            tag: "ic" as NodeTag,
            name: "Iceland",
            position: [42.701570877768056, 20.578231292517007],
            continent: "eu" as ContinentTag
        },
        "gb": {
            tag: "gb" as NodeTag,
            name: "Great Britain",
            position: [41.4065136297762, 30.782312925170068],
            continent: "eu" as ContinentTag
        },
        "sc": {
            tag: "sc" as NodeTag,
            name: "Scandinavia",
            position: [50.707379319899495, 20.578231292517007],
            continent: "eu" as ContinentTag
        },
        "uk": {
            tag: "uk" as NodeTag,
            name: "Ukraine",
            position: [58.359990330760425, 26.87074829931973],
            continent: "eu" as ContinentTag
        },
        "eun": {
            tag: "eun" as NodeTag,
            name: "Northern Europe",
            position: [49.1768571177273, 32.31292517006803],
            continent: "eu" as ContinentTag
        },
        "euw": {
            tag: "euw" as NodeTag,
            name: "Western Europe",
            position: [43.643430694489396, 43.36734693877551],
            continent: "eu" as ContinentTag
        },
        "eus": {
            tag: "eus" as NodeTag,
            name: "Southern Europe",
            position: [50.47191436571915, 41.66666666666667],
            continent: "eu" as ContinentTag
        },
        "afn": {
            tag: "afn" as NodeTag,
            name: "North Africa",
            position: [45.40941785084192, 59.693877551020414],
            continent: "af" as ContinentTag
        },
        "eg": {
            tag: "eg" as NodeTag,
            name: "Egypt",
            position: [54.239353632604534, 55.10204081632652],
            continent: "af" as ContinentTag
        },
        "co": {
            tag: "co" as NodeTag,
            name: "Congo",
            position: [53.88615620133403, 71.7687074829932],
            continent: "af" as ContinentTag
        },
        "afe": {
            tag: "afe" as NodeTag,
            name: "East Africa",
            position: [59.53731510166212, 66.3265306122449],
            continent: "af" as ContinentTag
        },
        "afs": {
            tag: "afs" as NodeTag,
            name: "South Africa",
            position: [54.47481858678488, 85.03401360544217],
            continent: "af" as ContinentTag
        },
        "mdg": {
            tag: "mdg" as NodeTag,
            name: "Madagascar",
            position: [63.18702189145733, 85.03401360544217],
            continent: "af" as ContinentTag
        },
        "me": {
            tag: "me" as NodeTag,
            name: "Middle East",
            position: [61.30330225801464, 49.31972789115646],
            continent: "as" as ContinentTag
        },
        "afg": {
            tag: "afg" as NodeTag,
            name: "Afghanistan",
            position: [66.01260134162136, 36.734693877551024],
            continent: "as" as ContinentTag
        },
        "in": {
            tag: "in" as NodeTag,
            name: "India",
            position: [72.84108501285112, 52.55102040816326],
            continent: "as" as ContinentTag
        },
        "si": {
            tag: "si" as NodeTag,
            name: "Siam",
            position: [80.96462593207274, 56.4625850340136],
            continent: "as" as ContinentTag
        },
        "ch": {
            tag: "ch" as NodeTag,
            name: "China",
            position: [78.1390464819087, 43.197278911564624],
            continent: "as" as ContinentTag
        },
        "ur": {
            tag: "ur" as NodeTag,
            name: "Ural",
            position: [68.60271583760506, 24.65986394557823],
            continent: "as" as ContinentTag
        },
        "sib": {
            tag: "sib" as NodeTag,
            name: "Siberia",
            position: [74.13614226084297, 19.5578231292517],
            continent: "as" as ContinentTag
        },
        "ya": {
            tag: "ya" as NodeTag,
            name: "Yakursk",
            position: [80.96462593207274, 13.26530612244898],
            continent: "as" as ContinentTag
        },
        "ka": {
            tag: "ka" as NodeTag,
            name: "Kamtchaka",
            position: [87.32217969494181, 17.346938775510203],
            continent: "as" as ContinentTag
        },
        "ir": {
            tag: "ir" as NodeTag,
            name: "Irkutsk",
            position: [79.66956868408089, 25.68027210884354],
            continent: "as" as ContinentTag
        },
        "mo": {
            tag: "mo" as NodeTag,
            name: "Mongolia",
            position: [82.14195070297441, 34.183673469387756],
            continent: "as" as ContinentTag
        },
        "ja": {
            tag: "ja" as NodeTag,
            name: "Japan",
            position: [91.0896189618272, 34.69387755102041],
            continent: "as" as ContinentTag
        },
        "ie": {
            tag: "ie" as NodeTag,
            name: "Indonesia",
            position: [82.73061308842526, 70.23809523809523],
            continent: "au" as ContinentTag
        },
        "ng": {
            tag: "ng" as NodeTag,
            name: "New Guinea",
            position: [90.38322409928618, 67.17687074829932],
            continent: "au" as ContinentTag
        },
        "auw": {
            tag: "auw" as NodeTag,
            name: "Western Australia",
            position: [86.49805235531063, 84.01360544217688],
            continent: "au" as ContinentTag
        },
        "aue": {
            tag: "aue" as NodeTag,
            name: "Eastern Australia",
            position: [94.73932575162242, 81.63265306122449],
            continent: "au" as ContinentTag
        }
    },
    edges: [
        ["aue", "ng"],
        ["ng", "ie"],
        ["ie", "auw"],
        ["auw", "aue"],
        ["auw", "ng"],
        ["ie", "si"],
        ["si", "ch"],
        ["ch", "mo"],
        ["mo", "ja"],
        ["ja", "ka"],
        ["ka", "ya"],
        ["ya", "ir"],
        ["ir", "sib"],
        ["sib", "ur"],
        ["ur", "afg"],
        ["afg", "in"],
        ["in", "me"],
        ["me", "afe"],
        ["afe", "mdg"],
        ["mdg", "afs"],
        ["afs", "co"],
        ["co", "afn"],
        ["afn", "eg"],
        ["eg", "eus"],
        ["eus", "uk"],
        ["uk", "eun"],
        ["eun", "euw"],
        ["euw", "gb"],
        ["gb", "sc"],
        ["sc", "ic"],
        ["ic", "gl"],
        ["gl", "nwt"],
        ["nwt", "ala"],
        ["ala", "alb"],
        ["alb", "on"],
        ["on", "qe"],
        ["qe", "use"],
        ["use", "usw"],
        ["usw", "ca"],
        ["ca", "ve"],
        ["ve", "p"],
        ["p", "ar"],
        ["ar", "br"],
        ["br", "p"],
        ["br", "ve"],
        ["br", "afn"],
        ["ca", "use"],
        ["usw", "alb"],
        ["on", "usw"],
        ["on", "use"],
        ["alb", "nwt"],
        ["on", "nwt"],
        ["on", "gl"],
        ["gl", "qe"],
        ["gb", "ic"],
        ["gb", "eun"],
        ["eun", "sc"],
        ["sc", "uk"],
        ["uk", "ur"],
        ["ur", "ch"],
        ["ch", "afg"],
        ["uk", "me"],
        ["eus", "euw"],
        ["euw", "afn"],
        ["afn", "afe"],
        ["co", "afe"],
        ["afe", "eg"],
        ["afs", "afe"],
        ["eg", "me"],
        ["me", "eus"],
        ["eus", "afn"],
        ["eus", "eun"],
        ["uk", "afg"],
        ["afg", "me"],
        ["in", "ch"],
        ["ir", "mo"],
        ["ir", "ka"],
        ["mo", "ka"],
        ["ch", "sib"],
        ["sib", "mo"],
        ["sib", "ya"],
        ["in", "si"],
        ["ka", "ala"]
    ] as [NodeTag, NodeTag][],
    continents: {
        eu: {
            tag: "eu" as ContinentTag,
            name: "Europe",
            bonusTroops: 5
        },
        as: {
            tag: "as" as ContinentTag,
            name: "Asia",
            bonusTroops: 7
        },
        af: {
            tag: "af" as ContinentTag,
            name: "Africa",
            bonusTroops: 3
        },
        au: {
            tag: "au" as ContinentTag,
            name: "Australia",
            bonusTroops: 2
        },
        sa: {
            tag: "sa" as ContinentTag,
            name: "South America",
            bonusTroops: 2
        },
        na: {
            tag: "na" as ContinentTag,
            name: "North America",
            bonusTroops: 5
        }
    }
};
export default initialGameState;
