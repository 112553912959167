import { EdgeList } from "./adjacency";
import { TurnLogEntry } from "./TurnLog";


declare const nodeTagSymbol: unique symbol;
export type NodeTag = string & { [nodeTagSymbol]: never };

declare const playerTagSymbol: unique symbol;
export type PlayerTag = string & { [playerTagSymbol]: never };

declare const continentTagSymbol: unique symbol;
export type ContinentTag = string & { [continentTagSymbol]: never };

export type GamePhase =
    | InitialPlacementPhase
    | PlacementPhase
    | MovementPhase
    | AttackPhase
    | OngoingAttackPhase

export interface InitialPlacementPhase {
    type: "initialPlacement"
    roundsLeft: number;
}
export interface PlacementPhase {
    type: "place";
    availableTroops: number;
    troopDistribution: { [node: NodeTag]: number }
}
export interface MovementPhase {
    type: "move";
    /**
     * The number of moves carried out in this turn.
     * Used to determine if more moves are allowed if move limits are enabled.
     */
    numberOfMoves: number;
}
export interface AttackPhase {
    type: "attack";
    /**
     * The number of attacks carried out in this turn.
     * Used to determine if more attacks are allowed if attack limits are enabled.
     */
    numberOfAttacks: number;
    hasReceivedCard: boolean;
}
export interface OngoingAttackPhase {
    type: "ongoingAttack";
    from: NodeTag;
    to: NodeTag;
    remaining: number;
    /** 
     * The number of attacks carried out in this turn.
     * @see AttackPhase.numberOfAttacks
     */
    numberOfAttacks: number;
    hasReceivedCard: boolean;
}

export interface GameMap {
    nodes: TagMap<MapNode>;
    edges: EdgeList;
    continents: TagMap<Continent>;
    baseMap: BaseMap;
}

export interface BaseMap {
    /** URL to the background image. */
    image: string,
    /** 
     * The witdth of the map, in pixels. 
     * The map may be scaled to be larger or smaller than this value, but the 
     * aspect ratio will always be kept at {@link width}/{@link height}.
     **/
    width: number,
    /**
     * The height of the map, in pixels.
     * The map may be scaled to be larger or smaller than this value, but the 
     * aspect ratio will always be kept at {@link width}/{@link height}.
     */
    height: number,
}

export interface GameState extends GameMap {
    nodes: TagMap<GameNode>;
    players: TagMap<Player>;
    playerOrder: PlayerTag[];
    currentPlayer: PlayerTag;
    currentPhase: GamePhase;
    rules: GameRules;
    turnLog: TurnLogEntry[];
}

export interface GameRules {
    attackLimit: number | null,
    moveLimit: number | null,
}

export type TagMap<T extends { tag: string }> = { [key: string]: T }

export interface GameNode extends MapNode {
    troops: number;
    owner: PlayerTag;
    continent: ContinentTag;
}

export interface MapNode {
    tag: NodeTag;
    name: string,
    position: [number, number];
    continent: ContinentTag,
}

export interface Player {
    tag: PlayerTag;
    color: string;
    textColor: string;
    name: string;
    cards: PlayerCards;
    /** 
     * The place that the player archieved in this game. 
     * Will be `undefined` if the player is still in the game.
     **/
    place?: number | undefined;
    portrait: string,
}

export type PlayerCards = {
    [Tier in 1 | 2 | 3]: number;
}

export const NoCards: PlayerCards = Object.freeze({ 1: 0, 2: 0, 3: 0 });
export const totalCards = (cards: PlayerCards) => cards[1] + cards[2] + cards[3];

export interface Continent {
    tag: ContinentTag;
    name: string;
    bonusTroops: number;
}
