import { Operation } from "fast-json-patch";
import * as immer from 'immer';

export default function jsonPatchesToImmerPatches(jsonPatches: Operation[]): immer.Patch[] {
    return jsonPatches.map(x => ({
        ...x,
        path: jsonPathToArray(x.path)
    })) as immer.Patch[];
}
function jsonPathToArray(path: string): string[] {
    // The JSON Patch definition defines the path as a RFC6901 JSON Path [1].
    // The JSON Path definition contains some escaping rules that we have to
    // follow [2].
    // [1]: https://datatracker.ietf.org/doc/html/rfc6902#section-4
    // [2]: https://datatracker.ietf.org/doc/html/rfc6901#section-3

    // remove the leading slash, split at the others
    const parts = path.replace(/^\//, "").split("/");

    // replace escaped characters (~1 and ~0)
    const unescaped = parts.map(x => x.replaceAll("~1", "/").replaceAll("~0", "~"));
    return unescaped;
}
