import React from 'react';
import { useParams } from 'react-router-dom';
import GameClient from '../net/client';
import { setNetClient, useAppDispatch, useAppSelector } from '../store/Store';
import { initChooseName, joinGame } from "../store/specialActions";
import { ChoosePlayerName } from '../lobby/ChoosePlayerName';
import { Lobby } from '../lobby/Lobby';
import { ClientReconnector } from '../net/ClientReconnector';
import { Game } from './Game';
import { apiBaseUrl, websocketUrl } from '../net/urls';

export function MultiplayerGame() {
    const { gameId } = useParams<{ gameId: string; }>();
    const currentMode = useAppSelector(state => state.mode);
    const playerTag = useAppSelector(state =>
        (state.mode === "connecting"
            || state.mode === "lobby"
            || state.mode === "multiplayer") ?
            state.playerTag : undefined);
    if (process.env.NODE_ENV === "development") {
        // In development mode, we have the additional /game/:gameId/:playerId
        // URL, which skips the chooseName step and just connects directly.

        /* eslint-disable react-hooks/rules-of-hooks*/
        const { playerId } = useParams<{ playerId: string; }>();
        const dispatch = useAppDispatch();
        /* eslint-enable react-hooks/rules-of-hooks*/
        if (playerTag === undefined && playerId !== undefined) {
            dispatch(joinGame({ name: playerId }))
        }
    }

    React.useEffect(() => {
        // While we're still waiting for the player to enter a name, do not attempt to connect.
        if (playerTag === undefined) return;

        const url = websocketUrl();
        if (process.env.NODE_ENV === "development") {
            const reconnector = new ClientReconnector(url, gameId, playerTag);
            return () => reconnector.disconnect();
        }
        else {
            const clientPromise = (async () => {
                const client = await GameClient.connect(url, gameId, playerTag);
                setNetClient(client);
                return client;
            })();
            return () => clientPromise.then(client => client?.close("Player left the game."));
        }
    }, [gameId, playerTag]);
    const connected = useAppSelector(state => (state.mode === "lobby" || state.mode === "multiplayer") ? state.connection !== "disconnected" : true);
    if (!connected) {
        return <div className="connection-state">Disconnected.</div>
    }
    switch (currentMode) {
        case "connecting":
            return <div className="connection-state">Connecting...</div>
        case "chooseName":
            return <ChoosePlayerName />
        case "lobby":
            return <Lobby />
        case "multiplayer":
            return <Game />
        default:
            return <InitChooseName />
    }
}

function InitChooseName() {
    const dispatch = useAppDispatch();
    const { gameId } = useParams<{ gameId: string; }>();
    const [statusString, setStatusString] = React.useState("Loading game info...");
    React.useEffect(() => {
        const loadData = async () => {
            const request = await fetch(`${apiBaseUrl()}/gameInfo/${gameId}`);
            if (request.status === 200) {
                const response = await request.json();
                dispatch(initChooseName(response));
            }
            else if (request.status === 404) {
                setStatusString(`Can't find game ${gameId}`);
            }
            else {
                setStatusString("Server error: " + await request.text());
            }
        };
        loadData();
    }, [gameId, dispatch]);
    return <div className="connection-state">{statusString}</div>
}
