import classNames from "classnames";
import React from "react";
import { tradeCards } from "../logic/gameSlice";
import { PlayerTag } from "../logic/GameState";
import { getRemainingTroops } from "../logic/getRemainingTroops";
import nodesByContinent from "../logic/nodesByContinent";
import nodesByOwner from "../logic/nodesByOwner";
import { tradeCardsForTroops } from "../logic/tradeCardsForTroops";
import { useGameState, useIsHistorical } from "../store/selectorHooks";
import { useAppDispatch, useAppSelector } from "../store/Store";
import { Tooltip } from "../ui-utils/Tooltip";
import "./PlayerInfo.css"
import PlayerBox from "./PlayerBox";
import arrayEquals from "../arrayEquals";

export default function PlayerInfo() {

    const playerOrder = useGameState(state => state.playerOrder);

    return <div className="player-info-list">
        {playerOrder.map(player => <PlayerInfoDisplay key={player} player={player} />)}
    </div>;
}

function PlayerInfoDisplay(props: { player: PlayerTag }) {
    const isPlayersTurn = useGameState(state =>
        state.currentPlayer === props.player
    );
    return <div className={classNames("player-info", { "player-info-active": isPlayersTurn })}>
        <PlayerBox player={props.player}>
            <div className="player-info-data">
                <PlayerInfoCards player={props.player} />
                <PlayerInfoContinents player={props.player} />
                <PlayerInfoTerritories player={props.player} />
                <PlayerInfoTroops player={props.player} />
            </div>
        </PlayerBox>
        <PlayerInfoState player={props.player} />
    </div>
}

function PlayerInfoCards(props: { player: PlayerTag }) {
    const isHistorical = useIsHistorical();
    const playerName = useGameState(state => state.players[props.player].name);
    const cards = useGameState(state => state.players[props.player]).cards;
    const revealCards = useAppSelector(state =>
        (state.mode === "singleplayer" && state.game.currentPlayer === props.player)
        || (state.mode === "multiplayer" && state.playerTag === props.player))
    const cardInfo = revealCards ?
        <>{cards[1]}/{cards[2]}/{cards[3]}</> :
        <>{cards[1] + cards[2] + cards[3]}</>;

    const phase = useGameState(state => state.currentPhase);
    const trade = tradeCardsForTroops(cards);
    const canTradeIn = revealCards && phase.type === "place" && trade !== null;
    const dispatch = useAppDispatch();

    const tooltipInfo =
        revealCards ?
            <>You have the following cards:
                <ul>
                    <li>{cards[1]} Infantry</li>
                    <li>{cards[2]} Cavalry</li>
                    <li>{cards[3]} Artillery</li>
                </ul>
                {canTradeIn && `Click to trade in (+${trade.gainedTroops}).`}
            </> :
            <>{playerName} has {cards[1] + cards[2] + cards[3]} cards.</>;


    return <Tooltip>
        {tooltipInfo}
        <div className={classNames("player-info-row", { "player-info-row-clickable": canTradeIn })}
            onClick={() => !isHistorical && canTradeIn && dispatch(tradeCards())}>
            <div className="player-info-symbol">
                ★ {/* card symbol (joker) */}
            </div>
            <div className="player-info-span">
                {cardInfo}
            </div>
        </div>
    </Tooltip>
}

function PlayerInfoContinents(props: { player: PlayerTag }) {
    const playerName = useGameState(state => state.players[props.player].name);
    const continents = useGameState(state =>
        Object.entries(nodesByContinent(state.nodes))
            .filter(([continentTag, nodes]) => nodes.every(node => node.owner === props.player))
            .map(([continentTag, nodes]) => state.continents[continentTag]), arrayEquals);

    const tooltip = continents.length > 0 ? <>
        {playerName} controls these continents:
        <ul>
            {continents.map(x => <li key={x.tag}>{x.name} (+{x.bonusTroops} troops per turn)</li>)}
        </ul>
    </> : <>{playerName} does not control any continents.</>

    return <Tooltip>
        {tooltip}
        <div className="player-info-row">
            <div className="player-info-symbol">
                🌍 {/* continent symbol (globe) */}
            </div>
            <div>
                {continents.length}
            </div>
            <div>
                (+{continents.map(x => x.bonusTroops).reduce((a, b) => a + b, 0)})
            </div>
        </div>
    </Tooltip>
}

function PlayerInfoTerritories(props: { player: PlayerTag }) {
    const playerName = useGameState(state => state.players[props.player].name);
    const territories = useGameState(state => nodesByOwner(state.nodes)[props.player] ?? []);
    const troopsPerTurn = Math.max(Math.floor(territories.length / 3), 3);

    const tooltip = <>{playerName} controls {territories.length} territories, giving them {troopsPerTurn} troops per turn.</>

    return <Tooltip>
        {tooltip}
        <div className="player-info-row">
            <div className="player-info-symbol">
                🚩  {/* province symbol (flag) */}
            </div>
            <div>
                {territories.length}
            </div>
            <div>
                (+{troopsPerTurn})
            </div>
        </div>
    </Tooltip>
}


function PlayerInfoTroops(props: { player: PlayerTag }) {
    const playerName = useGameState(state => state.players[props.player].name);
    const troops = useGameState(state => (nodesByOwner(state.nodes)[props.player] ?? [])
        .map(x => x.troops)
        .reduce((a, b) => a + b, 0))

    const tooltip = <>{playerName} has {troops} troops deployed in total.</>

    return <Tooltip>
        {tooltip}
        <div className="player-info-row">
            <div className="player-info-symbol">
                👥  {/* troop symbol (busts) */}
            </div>
            <div className="player-info-span">
                {troops}
            </div>
        </div>
    </Tooltip>
}

function PlayerInfoState(props: { player: PlayerTag }) {
    const isHistorical = useIsHistorical();
    const currentPhase = useGameState(state => {
        if (state.currentPlayer !== props.player)
            return "waiting";
        return state.currentPhase;
    });
    const rules = useGameState(state => state.rules);
    let breadcrumbStates = [false, false, false];
    let playerStateUi: React.ReactNode = <></>;
    if (isHistorical) {
        breadcrumbStates = [false, false, false];
        playerStateUi = <>(past turn)</>
    }
    else if (currentPhase === "waiting") {
        playerStateUi = <>Waiting</>;
    }
    else if (currentPhase.type === "initialPlacement") {
        playerStateUi = <>Initial placements ({currentPhase.roundsLeft})</>;
    }
    else if (currentPhase.type === "place") {
        const { availableTroops } = currentPhase;
        const placed = availableTroops - getRemainingTroops(currentPhase);
        playerStateUi = <>Placing ({placed}/{availableTroops})</>
        breadcrumbStates = [true, false, false];
    }
    else if (currentPhase.type === "attack" || currentPhase.type === "ongoingAttack") {
        const limit = rules.attackLimit;
        if (limit !== null) {
            playerStateUi = <>
                Attacking ({currentPhase.numberOfAttacks}/{limit})
            </>
        }
        else {
            playerStateUi = <>
                Attacking
            </>
        }
        breadcrumbStates = [false, true, false];
    }
    else if (currentPhase.type === "move") {
        const limit = rules.moveLimit;
        if (limit !== null) {
            playerStateUi = <>
                Moving ({currentPhase.numberOfMoves}/{limit})
            </>
        }
        else {
            playerStateUi = <>
                Moving
            </>
        }
        breadcrumbStates = [false, false, true];
    }
    else {
        const never: never = currentPhase;
        throw new Error(`Invalid phase: ${(never as any).type}!`);
    }
    return <div className="player-info-state">
        <PhaseBreadcrumbs states={breadcrumbStates} />
        {playerStateUi}
    </div>
}

function PhaseBreadcrumbs(props: { states: boolean[] }) {
    const elements = [];
    let i = 0;
    for (const state of props.states) {
        if (elements.length !== 0) {
            elements.push(<div key={i++} className="player-info-breadcrumbs-line" />)
        }
        elements.push(<div key={i++} className={classNames("player-info-breadcrumbs-circle", { "player-info-breadcrumbs-active": state })} />)
    }
    return <div className="player-info-breadcrumbs">
        {elements}
    </div>
}
