/**
 * Caches a simple mapping function using a WeakMap.
 * @param map The function to be cached. It should be pure.
 * @returns A cached version of `map`.
 */
export function makeWeakMapCached<TSource extends Object, TTarget>(map: (x: TSource) => TTarget) {
    const cache = new WeakMap<TSource, TTarget>();
    return (x: TSource) => {
        let result = cache.get(x);
        if (result === undefined) {
            result = map(x);
            cache.set(x, result);
        }
        return result;
    };
}
