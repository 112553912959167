import _ from 'lodash';
import React from 'react';
import { useGameSelector, useIsHistorical } from "../store/selectorHooks";
import useIsMyTurn from './useIsMyTurn';

export function HintsDisplay() {
  const [displayInstructions, setDisplayInstructions] = React.useState(true);

  return <div className="map-instructions">
    {displayInstructions &&
      <div className="map-instructions-text">
        <HintText />
      </div>
    }
    <button className="map-instructions-remove" onClick={() => setDisplayInstructions(x => !x)}>
      {displayInstructions ? "Hide hints" : "Show hints"}
    </button>
  </div>;
}

function HintText() {
  const isHistorical = useIsHistorical();
  const selection = useGameSelector(state => state.selection.type);
  const phase = useGameSelector(state => state.game.currentPhase);
  const isMyTurn = useIsMyTurn();
  const currentPlayer = useGameSelector(state => state.game.currentPlayer);

  const hasAttacked = useGameSelector(state => state.game.turnLog.some(x => x.type === "attack"));
  const hasMoved = useGameSelector(state => state.game.turnLog.some(x => x.type === "move"));

  if (isHistorical) {
    return <>
      You're viewing a historical game state.
      Press the ⇥ button in the top right to return to the current state.
    </>
  }

  if (!isMyTurn) {
    return <>Please wait for {currentPlayer}'s turn.</>
  }

  switch (phase.type) {
    case "initialPlacement":
      return <>Everyone places their initial troops now. Click the province that you want to place your next troop on.</>
    case "place":
      const hasPlacedTroops = Object.values(phase.troopDistribution).reduce(_.add, 0) > 0;
      return <>
        It's your turn to place troops.
        Click a province of yours to add a solider.
        You can also hover the province and use the mouse wheel to distribute the troops.
        <div>{hasPlacedTroops ? "You can use the right mouse button to correct your choice." : <>&nbsp;</>}</div>
      </>
    case "attack":
      switch (selection) {
        case "noneSelected":
          if (!hasAttacked)
            return <>
              You can attack now.
              Click the province from which you want to attack, then click the province you want to attack.
              If you don't want to attack, click the button in the bottom right.
            </>
          else
            return <>
              If you want to attack again, select another province of yours.<br />
              If you're done with your attacks, click the button in the bottom right.
            </>
        case "firstSelected":
          return <>Select the province you want to attack, or click anywhere else to abort.</>
        case "bothSelected":
          return <>Use the left and right mouse buttons or the wheel to select the number of troops you want to use for this attack. Click the checkmark once you're done.</>
        default:
          return <></>;
      }
    case "ongoingAttack":
      return <>You're attacking now. Click the arrow to roll the dice.</>
    case "move":
      switch (selection) {
        case "noneSelected":
          if (!hasMoved)
            return <>
              You can move troops now.
              Click the province from which you want to move, then click the target province.
              If you don't want to move any troops, click the button in the bottom right.
            </>
          else
            return <>
              If you want to move more troops, select another pair of provinces.<br />
              If you're done with your turn, click the button in the bottom right.
            </>
        case "firstSelected":
          return <>Select the province you want to move troops to, or click anywhere else to abort.</>
        case "bothSelected":
          return <>Use the left and right mouse buttons or the wheel to select the number of troops you want to move. Click the checkmark once you're done.</>
        default:
          return <></>;
      }
    default:
      return <></>;
  }
}
