export default async function withTimeout<T>(promise: Promise<T>, ms: number): Promise<T | "timeout"> {
    let timeoutHandle: any;
    const next = await Promise.race([
        new Promise<"timeout">(resolve => {
            timeoutHandle = setTimeout(() => resolve("timeout"), ms);
        }),
        promise
    ]);
    if (next !== "timeout")
        clearTimeout(timeoutHandle);
    return next;
}
