import { ResourceMetadata, getMetadata as _getMetadata } from "./credits/ResourceMetadata";

const portraits = [
    "napoleon.jpg",
    "bismark.jpg",
    "victoria.jpg",
    "maria-theresia.jpg",
    "catherine.jpg",
    "muhammad-ali.jpg",
    "pius.jpg",
    "charles.jpg",
    "dowager.jpg",
    "tewodros.jpg",
    "meiji.jpg",
    "washington.jpg",
    "boliviar.jpg",
    "cook.jpg",
    "sitting-bull.jpg"
];
const portraitUrls = portraits.map(x => `/portraits/${x}`)

export interface PortraitMetadata extends ResourceMetadata {
    /** A short biography of the person depicted. */
    biography: string;
}

export const getMetadata = _getMetadata as (url: string) => Promise<PortraitMetadata>;

export default portraitUrls;
